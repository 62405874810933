import { createSlice } from "@reduxjs/toolkit";

export const cepSlice = createSlice({
  name: "cep",
  initialState: {
    value: 1,
  },
  reducers: {
    setcep: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setcep } = cepSlice.actions;

export const selectcep = (state) => state.cep.value;

export default cepSlice.reducer;
