import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../../components/bannerPaginas/Banner";
import { selectClube } from "../../reducers/clube_reducer";
import { selectemail } from "../../reducers/email_reducer";
import { selectEpoca } from "../../reducers/epoca_reducer";
import { resetPassword } from "../../services/auth_services";
import redIcon from "../../assets/icons/redIconSVG.svg";
import blueIcon from "../../assets/icons/blueIconSVG.svg";
import greenIcon from "../../assets/icons/greenIconSVG.svg";

import {
  getMensalidade,
  toggleMensalidadePorEmail,
} from "../../services/clube_services";
import { selectclubeData } from "../../reducers/clubeData_reducer";
import { toMonthName } from "../../services/functions_aux";
import { selectpagamentosTipo } from "../../reducers/pagamentos_tipo_reducer";
import { selectmensalidade } from "../../reducers/mensalidades_recuder";
import ExampleDialoga from "../../components/dialog/editar_dialog";
import AdicionarMensalidade from "../../components/dialog/pagamentos/adcionar_mensalidade";
import AdicionarAnuidade from "../../components/dialog/pagamentos/adicionar_anuidade";

function ClubeScreen() {
  var pagamentoTipo = useSelector(selectpagamentosTipo);
  var mensalidades = useSelector(selectmensalidade);
  var mapValor = { Valor: {} };
  var mapValorMensalidades = { Valor_mensalidades: {} };

  for (const key in pagamentoTipo) {
    console.log("Valores do Map: ", JSON.parse(pagamentoTipo[key]));
  }
  var clube = useSelector(selectClube);
  var email = useSelector(selectemail);
  var epoca = useSelector(selectEpoca);
  var clubeDataStringfied = useSelector(selectclubeData);
  var clubeDataJson = JSON.parse(clubeDataStringfied);
  const [isMensalidadeEmail, setMensalidadeEmail] = useState(
    clubeDataJson.mensalidades_por_email
  );

  console.log(clube);
  var listaMeses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  console.log(toMonthName(1)); // 👉️ "January"
  console.log(toMonthName(2)); // 👉️ "February"

  function handleChange(e) {
    const isChecked = e.target.checked;
    setMensalidadeEmail(isChecked);
    console.log(isChecked);
  }

  function gravarAlteracoes(isMensalidadePorEmail, clubeParam) {
    console.log("mensalidades por email");
    console.log(isMensalidadePorEmail);
    toggleMensalidadePorEmail(isMensalidadePorEmail, clubeParam);
  }

  return (
    <>
      <section className="container_clube">
        <Banner />

        <div className="container_formulario_clube">
          {/* Validade Referências MB */}
          <div className="validade_referencias">
            <div className="heading">
              <h3>Validade das referências multibanco</h3>
            </div>
            <div className="validade_input">
              <p>{clubeDataJson.multibanco_expire_time} Dias.</p>
            </div>
            {/*   <div className="validade_input">
              <Button>Editar</Button>
            </div> */}
          </div>

          {/* Mensalidades Ativas*/}
          <div className="mensalidades_ativas">
            <div className="heading">
              <h3>Meses para enviar mensalidade</h3>
            </div>

            <div className="mensalidades_grid_meses_container">
              <div className="mensalidades_grid_meses">
                {listaMeses.map((element, index) => {
                  return (
                    <div className="mes_individual_item" key={index}>
                      {clubeDataJson.Meses_Mensalidade.includes(element) ? (
                        <div className="mes_individual_ativo">
                          <div className="icone">
                            <img src={blueIcon} alt="icone" />
                          </div>
                          <div className="mes">
                            <p>{toMonthName(element)}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="mes_individual_inativo">
                          <div className="icone">
                            <img src={redIcon} alt="icone" />
                          </div>
                          <div className="mes">
                            <p>{toMonthName(element)}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Segurança */}
          <div className="seguranca">
            <div className="heading">
              <h3>Segurança</h3>
            </div>

            <div className="buttons_seguranca">
              <div className="button_seguranca">
                <Button
                  onClick={() => {
                    resetPassword(email);
                  }}
                >
                  <span class="material-symbols-outlined">lock_reset</span>
                  &nbsp;Repor a Palavra-Passe
                </Button>
              </div>
              <div className="button_seguranca">
                {/*   <Button><span class="material-symbols-outlined">event_busy</span>&nbsp;Mudar de Época</Button> */}
              </div>
            </div>
          </div>

          {/* Funcionalidades Email */}
          <div className="funcionalidades_email">
            <div className="heading">
              <h3>Funcionalidades</h3>
            </div>

            <div className="container_seguranca">
              <div className="icon">
                {isMensalidadeEmail ? (
                  <img src={greenIcon} alt="icone" />
                ) : (
                  <img src={redIcon} alt="icone" />
                )}
              </div>
              <div className="text">
                <p>
                  Enviar <strong>automaticamente</strong> mensalidades por email
                </p>
              </div>
            </div>

            <Container style={{ paddingTop: "100px" }} fluid>
              <Row>
                <Col className="col col-12">
                  <div className="heading">
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <h3 className="col-4">Pagamentos anuais</h3>
                      <div style={{ width: "0px" }}></div>
                      {/*   <AdicionarAnuidade></AdicionarAnuidade> */}
                    </div>
                  </div>
                </Col>
              </Row>
              <br></br>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  padding: "20px",
                  boxShadow: " 4px 4px 8px rgba(27, 85, 236, 0.1)",
                }}
              >
                <Row style={{ paddingBottom: "20px" }}>
                  <h4 class="col-4" style={{ textAlign: "center" }}>
                    Pagamento
                  </h4>
                  <h4 class="col-4" style={{ textAlign: "center" }}>
                    Valor
                  </h4>

                  <p class="col-4"> </p>
                </Row>
                {pagamentoTipo.map((value, index) => {
                  var keyAux;
                  var valueAux;
                  for (const key in JSON.parse(value)) {
                    keyAux = key;
                    valueAux = JSON.parse(value)[key];
                    mapValor.Valor[keyAux] = valueAux;
                  }
                  return (
                    <div key={index}>
                      <Container key={index.toString() + "container"} fluid>
                        <div key={index}>
                          <Row
                            key={index.toString() + "Row"}
                            style={{
                              textAlign: "center",
                              whiteSpace: "pre-wrap",
                              overflowWrap: "break-word",
                            }}
                          >
                            <p key={index.toString() + "nome"} class="col-4">
                              {keyAux}
                            </p>
                            <p key={index.toString() + "dn"} class="col-4">
                              {valueAux + "€"}
                            </p>

                            <div class="col-4">
                              <ExampleDialoga
                                mapValor={mapValor}
                                nome={keyAux}
                                valor={valueAux}
                              ></ExampleDialoga>
                            </div>
                          </Row>
                        </div>
                      </Container>
                    </div>
                  );
                })}
              </div>
              <Col
                style={{ paddingTop: "40px", paddingBottom: "20px" }}
                className="col col-12"
              >
                <div className="heading">
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <h3>Mensalidades</h3>
                    <div style={{ width: "40px" }}></div>
                    {/*  <AdicionarMensalidade> </AdicionarMensalidade> */}
                  </div>
                </div>
              </Col>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  padding: "20px",
                  boxShadow: " 4px 4px 8px rgba(27, 85, 236, 0.1)",
                }}
              >
                <Row style={{ paddingBottom: "20px" }}>
                  <h4 class="col-4" style={{ textAlign: "center" }}>
                    Pagamento
                  </h4>
                  <h4 class="col-4" style={{ textAlign: "center" }}>
                    Valor
                  </h4>

                  <p class="col-4"> </p>
                </Row>
                {mensalidades.map((value2, index2) => {
                  var keyAux;
                  var valueAux;
                  for (const key in JSON.parse(value2)) {
                    keyAux = key;
                    valueAux = JSON.parse(value2)[key];
                    mapValorMensalidades.Valor_mensalidades[keyAux] = valueAux;
                  }
                  return (
                    <div key={index2}>
                      <Container key={index2.toString() + "container"} fluid>
                        <div key={index2}>
                          <Row
                            key={index2.toString() + "Row"}
                            style={{
                              textAlign: "center",
                              whiteSpace: "pre-wrap",
                              overflowWrap: "break-word",
                            }}
                          >
                            <p key={index2.toString() + "nome"} class="col-4">
                              {keyAux}
                            </p>
                            <p key={index2.toString() + "dn"} class="col-4">
                              {valueAux + "€"}
                            </p>

                            <div class="col-4">
                              <ExampleDialoga
                                mapValor={mapValorMensalidades}
                                nome={keyAux}
                                valor={valueAux}
                              ></ExampleDialoga>
                            </div>
                          </Row>
                        </div>
                      </Container>
                    </div>
                  );
                })}
              </div>
            </Container>
          </div>

          {/* Tabela */}
        </div>

        {/*   <div className="submit_form_group">
        <div className="gravar">
          <a onClick={() => gravarAlteracoes(isMensalidadeEmail, clube)}>
          <span class="material-symbols-outlined">save</span>
            <span>Gravar alterações</span>
          </a>
        </div>

        <div className="cancelar">
          <a>
          <span class="material-symbols-outlined">close</span>
          <span>Cancelar</span>
          
          </a>
        </div>
      </div> */}
      </section>
    </>
  );
}

export default ClubeScreen;
