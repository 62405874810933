import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/bannerPaginas/Banner";
import ReferenciaDialog from "../../components/dialog/referencia_dialog";
import { mainColor } from "../../consts/constants";
import { selectCompras } from "../../reducers/compras_efetuadas_reduce";
import { selectcomprasPendentes } from "../../reducers/compras_pendentes_reducer";
import { selectpagamentosEmFalta } from "../../reducers/pagamentos_falta_reducer";

function PagamentosEmFalta() {
  var pagamentosEmFalt = useSelector(selectpagamentosEmFalta);

  var listaComprasEfe = useSelector(selectCompras);
  var listaComprasPen = useSelector(selectcomprasPendentes);

  var dateTime = new Date();

  const navigate = useNavigate();

  const handleOnClick = useCallback(
    () => navigate("/atletas/atleta", { replace: false }),
    [navigate]
  );

  var contadorEf = 0;
  var contadorPe = 0;

  function filtrarPorDataDeNascimento() {}

  function pesquisaPorNome() {}

  function pesquisaPorEmail() {}

  /* useEffect(() => {
    var listaAux = [];
    var listaAux2 = [];
    lista1.forEach((elem) => {
      if (JSON.parse(elem).tipoDaCompra === pagamentosEmFalt) {
        listaAux.push(JSON.parse(elem));
      }
      setListaDeComprasEfetuadas(listaAux);
    });

    lista2.forEach((elem) => {
      if (JSON.parse(elem).tipoDaCompra === pagamentosEmFalt) {
        listaAux2.push(JSON.parse(elem));
      }
      setListaDeComprasPendetes(listaAux2);
    });
  }, []); */

  return (
    <div className="container_atletas">
      <Banner />
      <div className="container_atletas_info">
        <div className="heading">
          <h3>{pagamentosEmFalt}</h3>
        </div>
        <div className="tabela_generica w-100 table-responsive">
          <Table className="table  table-hover ">
            <TableHead>
              <p
                style={{
                  color: mainColor,
                  fontSize: "30px",
                  fontWeight: "600",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                Pagamentos em Falta
              </p>

              <Col>
                <Row>
                  <Col>
                    <div class="input-group gap-3">
                      <input
                        id="filtrarPorData"
                        type="text"
                        maxlength="4"
                        name="country_code"
                        title="Error Message"
                        pattern="[1-9]{1}[0-9]{9}"
                        class=" form-control"
                        placeholder="Filtrar por data de nascimento"
                      ></input>
                      <div class="input-group-append col-4">
                        <button
                          class="btn d-flex align-items-center"
                          type="button"
                          onClick={() =>
                            filtrarPorDataDeNascimento(
                              document.getElementById("filtrarPorData").value
                            )
                          }
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                          &nbsp;
                          <span className="">Filtrar</span>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col>
                    <div class="input-group gap-3">
                      <input
                        id="pesquisarNome"
                        type="text"
                        class=" form-control"
                        placeholder="Pesquisar por nome"
                      />
                      <div class="input-group-append col-4">
                        <button
                          class="btn d-flex align-items-center "
                          type="button"
                          onClick={() => pesquisaPorNome()}
                        >
                          <span class="material-symbols-outlined">search</span>
                          &nbsp;
                          {/* "d-none d-sm-block */}
                          <span className="">Pesquisar</span>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col>
                    <div class="input-group gap-3">
                      <input
                        id="pesquisarEmail"
                        type="text"
                        class=" form-control"
                        placeholder="Pesquisar por email do encarregado"
                      ></input>
                      <div class="input-group-append col-4">
                        <button
                          class="btn d-flex align-items-center "
                          type="button"
                          onClick={() => pesquisaPorEmail()}
                        >
                          <span class="material-symbols-outlined">search</span>
                          &nbsp;
                          <span className="">Pesquisar</span>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <TableRow>
                <th scope="col"> Nome do Atleta </th>
                <th scope="col"> Valor</th>
                <th scope="col"> Expira em</th>
                <th scope="col">Opções:</th>
              </TableRow>
            </TableHead>

            <TableBody>
              {listaComprasPen.map((pagamentoString, index) => {
                var pagamento = JSON.parse(pagamentoString);
                if (pagamento.tipoDaCompra === pagamentosEmFalt) {
                  contadorPe++;
                  return (
                    <TableRow
                      key={index.toString() + "Row"}
                      className={contadorPe % 2 === 0 ? "blue" : "white"}
                    >
                      <TableCell>
                        <p>{pagamento.atletaNome}</p>
                      </TableCell>

                      <TableCell>
                        <p>{pagamento.valorDaCompra + "€"}</p>
                      </TableCell>

                      <TableCell>
                        <p>{pagamento.expiryAt}</p>
                      </TableCell>

                      <TableCell>
                        <div className="d-flex  m-0 p-0">
                          <div>
                            <ReferenciaDialog
                              atletaID={pagamento.atletaID}
                              encarregadoEmail={pagamento.encarregadoEmail}
                              tipoDaCompra={pagamento.tipoDaCompra}
                              docNum={pagamento.docNum}
                              estado={
                                dateTime
                                  .toLocaleDateString("pt-PT")
                                  .replaceAll("/", "-") > pagamento.expiryAt
                                  ? "Expirou"
                                  : "Pendente"
                              }
                              valorRef={pagamento.valorDaCompra}
                              compraID={pagamento.idAux}
                              referencia={" " + pagamento.reference}
                              entidade={" " + pagamento.entidade}
                              valor={" " + pagamento.valorDaCompra}
                              expira={" " + pagamento.expiryAt}
                            ></ReferenciaDialog>
                          </div>

                          <div>
                            <Button
                              onClick={async () => {
                                handleOnClick();
                              }}
                            >
                              Atleta
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                }
                if (contadorPe === 0 && listaComprasPen.length === index + 1) {
                  return (
                    <h5 style={{ paddingTop: "40px", paddingLeft: "6px" }}>
                      Sem pagamentos Pendentes
                    </h5>
                  );
                } else {
                  return null;
                }
              })}
            </TableBody>
            <TableHead>
              <p
                style={{
                  color: mainColor,
                  fontSize: "30px",
                  fontWeight: "600",
                  paddingTop: "60px",
                  paddingBottom: "20px",
                }}
              >
                Pagamentos Efetuados
              </p>

              <TableRow>
                <th scope="col"> Nome do Atleta </th>
                <th scope="col"> Valor</th>
                <th scope="col"> Pago em</th>
                <th scope="col">Opções:</th>
              </TableRow>
            </TableHead>
            <TableBody>
              {listaComprasEfe.map((pagamentoString, index) => {
                var pagamento = JSON.parse(pagamentoString);
                var date = null;
                if (pagamento.tipoDaCompra === pagamentosEmFalt) {
                  contadorEf++;
                  if (
                    pagamento.paidAt !== null &&
                    typeof pagamento.paidAt !== "undefined"
                  ) {
                    date = new Date(pagamento.paidAt.seconds * 1000);
                  }
                  return (
                    <TableRow
                      key={index.toString() + "Row"}
                      className={contadorEf % 2 === 0 ? "blue" : "white"}
                    >
                      <TableCell>
                        <p>{pagamento.atletaNome}</p>
                      </TableCell>

                      <TableCell>
                        <p>{pagamento.valorDaCompra + "€"}</p>
                      </TableCell>

                      <TableCell>
                        <p>
                          {date === null
                            ? ""
                            : date.toLocaleDateString().replaceAll("/", "-")}
                        </p>
                      </TableCell>

                      <TableCell>
                        <div className="d-flex  m-0 p-0">
                          <div></div>

                          <div>
                            <Button
                              onClick={async () => {
                                handleOnClick();
                              }}
                            >
                              Atleta
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                }
                if (contadorEf === 0 && listaComprasEfe.length === index + 1) {
                  return (
                    <h5 style={{ paddingTop: "40px", paddingLeft: "6px" }}>
                      Sem pagamentos Pendentes
                    </h5>
                  );
                } else {
                  return null;
                }
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
    // <Container className="p-5" fluid>

    //   {seguros.map((seguroI, index) => {
    //     var seguro = seguroI.data();

    //     return (
    //       <div
    //         key={index}
    //         style={{
    //           background: isEven(index)
    //             ? "rgba(27, 85, 236, .4)"
    //             : "rgba(255, 122, 89, .0)",
    //         }}
    //       >
    //         <Row
    //           className="d-flex align-items-center"
    //           style={{
    //             textAlign: "center",
    //             whiteSpace: "pre-wrap",
    //             overflowWrap: "break-word",
    //           }}
    //         >
    //           <Col xs={3} md={3} lg={3} style={{ paddingTop: "10px" }}>
    //             <p>{seguro.encarregadoNome}</p>
    //           </Col>
    //           <Col xs={3} md={3} lg={3} style={{ paddingTop: "10px" }}>
    //             <p>{seguro.estado}</p>
    //           </Col>
    //           <Col xs={3} md={3} lg={3} style={{ paddingTop: "10px" }}>
    //             <p>{seguro.valorDaCompra}</p>
    //           </Col>
    //           <Col xs={3} md={3} lg={3}>
    //             <div
    //               className="p-2"
    //               style={{
    //                 textalign: "center",
    //                 margin: "auto",
    //               }}
    //             >
    //               <ReferenciaDialog
    //                 tipoDaCompra={seguro.tipoDaCompra}
    //                 encarregadoEmail={seguro.encarregadoEmail}
    //                 estado={seguro.estado}
    //                 valorRef={seguro.valorDaCompra}
    //                 compraID={seguroI.id}
    //                 referencia={" " + seguro.reference}
    //                 entidade={" " + seguro.entidade}
    //                 valor={" " + seguro.valorDaCompra + "€"}
    //                 expira={" " + seguro.expiryDate}
    //               ></ReferenciaDialog>
    //             </div>
    //           </Col>
    //         </Row>
    //       </div>
    //     );
    //   })}
    // </Container>
  );
}

export default PagamentosEmFalta;
