import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import SideBar from "./components/sidebar";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "./reducers/auth_reducer";
import db, { auth } from "./firebase.config";
import LoginScreen from "./screens/auth/login_screen";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { selectClube, setClube } from "./reducers/clube_reducer";
import { selectEpoca, setEpoca } from "./reducers/epoca_reducer";
import LoadingSpinner from "./components/loading_spiner";
import { camisolaNrDocument, clubesCollection } from "./consts/constants";
import { selectemail, setemail } from "./reducers/email_reducer";
import { selectclubeId, setclubeId } from "./reducers/clube_id_reducer";
import {
  resetPagamentos,
  setpagamentosTipo,
} from "./reducers/pagamentos_tipo_reducer";
import {
  resetMensalidades,
  setmensalidade,
} from "./reducers/mensalidades_recuder";
import {
  resetValoresPagos,
  setvaloresPagos,
} from "./reducers/valores_pagos_reducer";
import {
  resetValoresAPagar,
  setvaloresAPagar,
} from "./reducers/valores_a_pagar_reducer";
import { setclubeData } from "./reducers/clubeData_reducer";
import {
  resetcomprasPendentes,
  setcomprasPendentes,
} from "./reducers/compras_pendentes_reducer";
import { resetCompras, setCompras } from "./reducers/compras_efetuadas_reduce";
import { async } from "@firebase/util";
import { selectcep, setcep } from "./reducers/cep_reducer";
import { setnumerosCamisola } from "./reducers/numeros_camisola_reducer";

function App() {
  console.log("rendering APp");
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState();
  var clube = useSelector(selectClube);
  var epoca = useSelector(selectEpoca);
  var email = useSelector(selectemail);
  var clubeId = useSelector(selectclubeId);
  var cep = useSelector(selectcep);

  var dispatch = useDispatch();

  async function fetchReducers() {
    console.log("fetchin reducers");
    const q = query(
      collection(db, clubesCollection),
      where("email", "==", email),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log("fetchin data from Clube");
      clubeId = doc.id;
      clube = doc.data().clube;
      epoca = doc.data().epoca;
      email = doc.data().email;
      cep = doc.data().cep;
      dispatch(setclubeData(JSON.stringify(doc.data())));
      dispatch(setClube(clube));
      dispatch(setEpoca(epoca));
      dispatch(setemail(email));
      dispatch(setclubeId(clubeId));
      dispatch(setcep(cep));
    });
  }

  async function fetchComprasAtraEEft() {
    const docRefCompras = doc(
      db,
      clubesCollection,
      clubeId,
      epoca,
      "comprasEfetuadasEPendentes1"
    );

    onSnapshot(docRefCompras, async (docCompras) => {
      console.log("fetchValoresV2");
      dispatch(resetcomprasPendentes());
      dispatch(resetCompras());
      const numeroDeDocs = cep;
      var listaDePendetens = [];
      var listaDeEfetuadas = [];
      for (const key in docCompras.data().Pendentes) {
        var jsonData = {};
        jsonData = docCompras.data().Pendentes[key];
        jsonData.idAux = key;
        jsonData.docNum = 1;
        listaDePendetens.push(JSON.stringify(jsonData));
      }
      for (const key2 in docCompras.data().Efetuadas) {
        var jsonData2 = {};
        jsonData2 = docCompras.data().Efetuadas[key2];
        jsonData2.idAux = key2;
        jsonData2.docNum = 1;
        listaDeEfetuadas.push(JSON.stringify(jsonData2));
      }
      if (numeroDeDocs >= 2) {
        for (let index = 2; index <= numeroDeDocs; index++) {
          console.log("fetchValoresV2 V3");
          onSnapshot(
            doc(
              db,
              clubesCollection,
              clubeId,
              epoca,
              "comprasEfetuadasEPendentes" + numeroDeDocs.toString()
            ),
            (docaData2) => {
              for (const key in docaData2.data().Pendentes) {
                var jsonData3 = {};

                jsonData3 = docCompras.data().Pendentes[key];
                jsonData3.idAux = key;
                jsonData3.docNum = index;
                listaDePendetens.push(JSON.stringify(jsonData3));
              }
              for (const key2 in docaData2.data().Efetuadas) {
                var jsonData4 = {};

                jsonData4 = docCompras.data().Efetuadas[key2];
                jsonData4.idAux = key2;
                jsonData4.docNum = index;
                listaDeEfetuadas.push(JSON.stringify(jsonData4));
              }
            }
          );
        }
      }

      dispatch(setcomprasPendentes(listaDePendetens));
      dispatch(setCompras(listaDeEfetuadas));
    });
  }

  async function fetchValoresV2() {
    try {
      console.log("Clube : ", clube);
      console.log("ClubeID : ", clubeId);
      console.log("Epoca : ", epoca);

      const docRef = doc(db, clubesCollection, clubeId, epoca, "pagamentos");
      onSnapshot(docRef, (doc) => {
        dispatch(resetPagamentos([]));
        dispatch(resetMensalidades([]));
        dispatch(resetValoresPagos());
        dispatch(resetValoresAPagar());

        var listaDePagamentosAxu = [];
        var listaDeMensalidadesAxu = [];
        console.log("Current data: ", doc.data());

        for (const key in doc.data().Valor) {
          listaDePagamentosAxu.push({ [key]: doc.data().Valor[key] });
        }
        for (const key2 in doc.data().Valor_mensalidades) {
          listaDeMensalidadesAxu.push({
            [key2]: doc.data().Valor_mensalidades[key2],
          });
        }
        console.log(listaDePagamentosAxu);
        dispatch(setpagamentosTipo(listaDePagamentosAxu));
        dispatch(setmensalidade(listaDeMensalidadesAxu));
        dispatch(setvaloresPagos(doc.data().Valor_pago));
        dispatch(setvaloresAPagar(doc.data().Valor_a_pagar));
      });
      renderApp();
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchNumerosDaCamisola() {
    var docRef = doc(db, clubesCollection, clubeId, epoca, camisolaNrDocument);
    onSnapshot(docRef, (doc) => {
      dispatch(setnumerosCamisola(JSON.stringify(doc.data())));
    });
  }

  // Handle user state changes
  function onAuthStateChanged(user) {
    setUser(user);
    if (initializing) setInitializing(false);
  }

  fetchReducers().then(async (value) => {
    await fetchValoresV2();
    await fetchComprasAtraEEft();
    fetchNumerosDaCamisola();
  });

  useEffect(() => {
    console.log("render App js");
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);

    return subscriber; // unsubscribe on unmount
  }, []);

  const renderApp = () => {
    if (auth.currentUser != null) {
      return (
        <div className="App">
          <SideBar />
        </div>
      );
    } else if (auth.currentUser != null && (clube === "" || epoca === "")) {
      return <LoadingSpinner></LoadingSpinner>;
    } else {
      console.log(auth.currentUser);
      return <LoginScreen></LoginScreen>;
    }
  };

  return <>{renderApp()}</>;
}

export default App;
