import { createSlice } from "@reduxjs/toolkit";

export const clubeDataSlice = createSlice({
  name: "clubeData",
  initialState: {
    value: [],
  },
  reducers: {
    setclubeData: (state, action) => {
        state.value = action.payload;
      },
  },
});

// Action creators are generated for each case reducer function
export const { setclubeData } = clubeDataSlice.actions;

export const selectclubeData = (state) => state.clubeData.value;

export default clubeDataSlice.reducer;
