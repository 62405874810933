import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { CDBSidebarMenuItem } from "cdbreact";
import { logout } from "../services/auth_services";
import logo from "../assets/sidebar/logo.png";
import { selectClube, setClube } from "../reducers/clube_reducer";
import { useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function SideBar() {
  // var toogled = "true";
  const [activeItem, setActiveItem] = useState("dashboard");
  const [activeSubItem, setActiveSubItem] = useState("");

  const [openAtleta, setOpenAtleta] = useState(false);
  const [openInsc, setOpenInsc] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("Copiar Link");

  // Toggle individual das Inscrições
  function handleClickInscricoes(value) {
    setOpenInsc(value);
  }

  // Toggle individual dos atletas
  function handleClickAtletas(value) {
    setOpenAtleta(value);
  }

  // Toggle multiplo para os submenus
  function handleAtletasInscricoes() {
    setOpenInsc(false);
    setOpenAtleta(false);
  }

  function chageActive(value) {
    setActiveItem(value);
    document.querySelector("aside").classList.toggle("openAside");
  }

  function chageSubActive(value) {
    setActiveSubItem(value);
    document.querySelector("aside").classList.toggle("openAside");
  }

  var clube = useSelector(selectClube);

  return (
    <div
      style={{
        width: "100vw",
        overflowY: "scroll",
        display: "flex",
        height: "100%",
        overflow: "scroll initial",
      }}
    >
      {/* if togglestate = true, aside_navigation adds openAside class */}
      <aside className="aside_navigation">
        <div className="aside_container">
          {/* Logotipo */}
          <div className="aside_logo">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="logo_name">
              <h1>{clube}</h1>
            </div>
          </div>

          {/* Nav Links */}
          <div className="aside_menu">
            <ul>
              {/* Home */}
              <li>
                <NavLink
                  to="/dashboard"
                  onClick={() => {
                    chageActive("dashboard");
                    handleAtletasInscricoes();
                  }}
                >
                  <div className="icon">
                    <span className="material-symbols-outlined">home</span>
                  </div>

                  <div className="link">
                    <p>Home</p>
                  </div>
                </NavLink>
              </li>

              {/* Clube */}
              <li>
                <NavLink
                  to="/clube"
                  onClick={() => {
                    chageActive("clube");
                    handleAtletasInscricoes();
                  }}
                >
                  <div className="icon">
                    <span className="material-symbols-outlined">
                      local_police
                    </span>
                  </div>
                  <div className="link">
                    <p>Clube</p>
                  </div>
                </NavLink>
              </li>

              {/* Inscrições */}
              <li>
                <a
                  onClick={() => {
                    handleClickInscricoes(true);
                    handleClickAtletas(false);
                  }}
                >
                  <div className="icon">
                    <span className="material-symbols-outlined">
                      local_police
                    </span>
                  </div>
                  <div className="link">
                    <p>Inscrições</p>
                  </div>
                </a>

                {openInsc == true ? (
                  <ul className="nested_ul " id="nested_teste">
                    <li>
                      <NavLink
                        to="/inscricoes"
                        onClick={() => chageActive("inscricoes")}
                      >
                        <div className="icon"></div>
                        <div className="link">
                          <p>Inscrições Pendentes</p>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href={"https://inscricao.macrofoot.pt/" + clube}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="icon"></div>
                        <div className="link">
                          <p>Link da Inscrição</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                ) : (
                  <ul className="nested_ul hidden" id="nested_teste">
                    <li>
                      <NavLink
                        to="/inscricoes"
                        onClick={() => chageActive("inscricoes")}
                      >
                        <div className="icon"></div>
                        <div className="link">
                          <p>Inscrições Pendentes</p>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href={"https://inscricao.macrofoot.pt/" + clube}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="icon"></div>
                        <div className="link">
                          <p>Link da Inscrição</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                )}
              </li>

              {/* Atletas */}
              <li>
                <a
                  onClick={() => {
                    handleClickAtletas(true);
                    handleClickInscricoes(false);
                  }}
                >
                  <div className="icon">
                    <span className="material-symbols-outlined">
                      sports_soccer
                    </span>
                  </div>
                  <div className="link">
                    <p>Atletas</p>
                  </div>
                </a>

                {openAtleta == true ? (
                  <ul className="nested_ul " id="nested_teste">
                    <li>
                      <NavLink
                        to="/atletas"
                        onClick={() => chageActive("atletas")}
                      >
                        <div className="icon"></div>
                        <div className="link">
                          <p>Atletas inscritos</p>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/atletaNaoInscrito"
                        onClick={() => chageActive("atletaNaoInscrito")}
                      >
                        <div className="icon"></div>
                        <div className="link">
                          <p>Atletas não inscritos</p>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                ) : (
                  <ul className="nested_ul hidden" id="nested_teste">
                    <li>
                      <NavLink
                        to="/atletas"
                        onClick={() => chageActive("atletas")}
                      >
                        <div className="icon"></div>
                        <div className="link">
                          <p>Atletas inscritos</p>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/atletaNaoInscrito"
                        onClick={() => chageActive("atletaNaoInscrito")}
                      >
                        <div className="icon"></div>
                        <div className="link">
                          <p>Atletas não inscritos</p>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>

          {/* Terminar Sessão */}
          <div className="footer_aside" onClick={() => logout()}>
            <a href="">Terminar Sessão</a>
          </div>
        </div>
      </aside>

      <Outlet></Outlet>
    </div>
  );
}
