import { createSlice } from "@reduxjs/toolkit";

export const mensalidadeSlice = createSlice({
  name: "mensalidade",
  initialState: {
    value: [],
  },
  reducers: {
    setmensalidade: (state, action) => {
      action.payload.forEach((element) =>
        state.value.push(JSON.stringify(element))
      );
    },
    resetMensalidades: (state, action) => {
      state.value = [];
    },
  },
});

export function mensalidadesToJson(listaMapStrings) {
  var listaJson = [];
  for (const key in listaMapStrings) {
    listaJson.push(JSON.parse(listaMapStrings[key]));
  }
  return listaJson;
}

// Action creators are generated for each case reducer function
export const { setmensalidade, resetMensalidades } = mensalidadeSlice.actions;

export const selectmensalidade = (state) => state.mensalidade.value;

export default mensalidadeSlice.reducer;
