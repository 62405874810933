import React from "react";

function TituloValor(props) {
  return (
    <div className="titulo_valor">
      <label>
        {props.titulo}
      </label>
      <input type="text" value={props.valor} disabled />
      
      
    </div>
  );
}

export default TituloValor;

/* 
<Container fluid>
        
        <Row>
          <Col
            className="col col-6"
            style={{ maxWidth: "100%", display: "flex" }}
          >
            <DashBoardMensalidades />
          </Col>
          <Col className="col col-6" style={{ maxWidth: "100%" }}>
            <DashBoardCard />
          </Col>
        </Row>
        <Row>
          <Col>
            <DashBoardCard />
          </Col>
          <Col>
            <DashBoardCard />
          </Col>
        </Row>
      </Container>
      
      */

/* 
    <Card className="text-center ">
        <Card.Header>{props.titulo}</Card.Header>
        <Card.Body className="p-3">
          <Row>
            <Col>
              <h4>Mês atual</h4>
              <p>Valor total a pagar: {props.valorAPagarMA} €</p>
              <p>Valor Pago:{props.valorPagoMA} €</p>
            </Col>
            <Col>
              <h4>Meses anteriores</h4>
              <p>Valor total a pagar: {props.valorAPagarMsAn} €</p>
              <p>Valor Pago:{props.valorPagoMsAn} €</p>
            </Col>
          </Row>
          <Button variant="primary">Ver Pagamentos em falta</Button>
        </Card.Body>
      </Card> */
