import { doc, addDoc, collection } from "firebase/firestore";
import db from "../firebase.config";

export async function enviarEmailPagamentos(emailTo, assunto, htmlbody) {
  try {
    console.log(htmlbody);
    console.log(assunto);
    console.log(emailTo);
    await addDoc(collection(db, "mail"), {
      to: emailTo,
      message: {
        subject: assunto,
        html: htmlbody,
      },
    });
  } catch (e) {
    console.log(e);
    return null;
  }
}

/* 
<p class="paragraph">
<img src="https://upload.wikimedia.org/wikipedia/commons/4/46/Multibanco.svg" alt="Smiley face image" class="image">
<span class="text">
  This is one line of text with  image on the left side <br><br>
  This is one line of text with  image on the left side <br><br>
  This is one line of text with  image on the left side <br><br>
</span>

</p> */

export const htmlBodyMail =
  " <style>" +
  ".text {" +
  " align-self: flex-end;" +
  "}" +
  ".image {" +
  " width: 120px;" +
  " height: 120px;" +
  "}" +
  ".paragraph {" +
  "display: flex;" +
  "}" +
  "</style>";

export function criaEmailBOdy(nome, valor, entidade, referencia, expiraEm) {
  var aretornar = "";
  aretornar =
    "<p>" +
    '<div style="font-weight: bold; font-size: 18px; padding-left: 20px; ">' +
    nome +
    "</div>" +
    ' <p class="paragraph">' +
    '<img src="https://upload.wikimedia.org/wikipedia/commons/4/46/Multibanco.svg" class="image">' +
    ' <span class="text">' +
    "Entidade: " +
    entidade +
    "<br><br>" +
    "Referência: " +
    referencia +
    "<br><br>" +
    "Valor: " +
    valor +
    "€" +
    "<br><br>" +
    "Expira em:" +
    expiraEm +
    "<br><br>" +
    " </span>" +
    "</p>" +
    "<br><br>";

  return aretornar;
}
