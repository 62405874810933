import { async } from "@firebase/util";

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function mapLength(map) {
  if (map === null || map === {}) {
    return 0;
  } else {
    var listaAux = [];
    for (const key in map) {
      listaAux.push(key);
    }
    return listaAux.length;
  }
}

export function toMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("pt-PT", {
    month: "long",
  });
}

