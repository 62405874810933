import { async } from "@firebase/util";
import {
  addDoc,
  collection,
  doc,
  increment,
  query,
  updateDoc,
  where,
  getDocs,
  setDoc,
  Timestamp,
  getDoc,
  deleteField,
  serverTimestamp,
} from "firebase/firestore";
import {
  atletasCollection,
  clubesCollection,
  comprasCollection,
  comprasEfetuadasECanceladas,
} from "../consts/constants";
import db from "../firebase.config";
import { setcep } from "../reducers/cep_reducer";
import store from "../store";

export async function adicionaCompra(compra, clubeID) {
  try {
    await atulizaValoresAPagarAoClube(compra, clubeID);
    const docRef = await addDoc(
      collection(db, comprasCollection),
      compra.toJson()
    );
    console.log("atulizaValoresAPagarAoClube Sucesso");
    return docRef;
  } catch (e) {
    console.log("error");
    console.error(e);
    return null;
  }
}

export async function adicionaCompraConcluida(compra, clubeID) {
  try {
    await atulizaValoresAPagarAoClubeConcluida(compra, clubeID);
    const docRef = await addDoc(
      collection(db, comprasCollection),
      compra.toJson()
    );
    console.log("atulizaValoresAPagarAoClube Sucesso");
    return docRef;
  } catch (e) {
    console.log("error");
    console.error(e);
    return null;
  }
}

export async function adicionaCompraAnulada(compra, clubeID) {
  try {
    const docRef = await addDoc(
      collection(db, comprasCollection),
      compra.toJson()
    );
    console.log("atulizaValoresAPagarAoClube Sucesso");
    return docRef;
  } catch (e) {
    console.log("error");
    console.error(e);
    return null;
  }
}

async function auxAdicionaCompraAoClube(docRef, compraId, compra) {
  await setDoc(
    docRef,
    {
      Pendentes: {
        [compraId]: {
          atletaID: compra.atletaId,
          atletaNome: compra.atletaNome,
          encarregadoEmail: compra.encarregadoEmail,
          tipoDaCompra: compra.tipoDaCompra,
          expiryAt: compra.expiryDate,
          mesDaCompra: compra.mesDaCompra,
          valorDaCompra: compra.valorDaCompra,
          reference: compra.reference,
          entidade: compra.entidade,
        },
      },
    },
    { merge: true }
  );
}

async function auxAdicionaCompraAoClubeConcluida(docRef, compraId, compra) {
  await setDoc(
    docRef,
    {
      Efetuadas: {
        [compraId]: {
          atletaID: compra.atletaId,
          atletaNome: compra.atletaNome,
          encarregadoEmail: compra.encarregadoEmail,
          tipoDaCompra: compra.tipoDaCompra,
          paidAt: null,
          mesDaCompra: compra.mesDaCompra,
          valorDaCompra: compra.valorDaCompra,
          reference: compra.reference,
          entidade: compra.entidade,
        },
      },
    },
    { merge: true }
  );
}

async function auxAdicionaCompraAoClubeAnulada(docRef, compraId, compra) {
  await setDoc(
    docRef,
    {
      Anuladas: {
        [compraId]: {
          atletaID: compra.atletaId,
          atletaNome: compra.atletaNome,
          encarregadoEmail: compra.encarregadoEmail,
          tipoDaCompra: compra.tipoDaCompra,
          paidAt: null,
          mesDaCompra: compra.mesDaCompra,
          valorDaCompra: compra.valorDaCompra,
          reference: compra.reference,
          entidade: compra.entidade,
        },
      },
    },
    { merge: true }
  );
}

export async function atualizarNumeroDoCEPNaCompra(compraID, cep) {
  try {
    var docRef = doc(db, comprasCollection, compraID);
    await setDoc(docRef, { cep: cep }, { merge: true });
  } catch (error) {
    console.error(error);
  }
}

export async function adicionaCompraAoClube2Anulada(
  cep,
  compraId,
  compra,
  clubeID,
  epoca
) {
  console.log("adicionaCompraAoClube2");
  var cepDoc = "comprasEfetuadasEPendentes" + cep;
  const docRef = doc(db, clubesCollection, clubeID, epoca, cepDoc);

  try {
    console.log(cep);
    console.log("cep");
    await auxAdicionaCompraAoClubeAnulada(docRef, compraId, compra);
    return cep;
  } catch (error) {
    console.error("Erro ao adicionar compra ao Clube");
    if (error.message === "too many index entries for entity") {
      cep++;
      store.dispatch(setcep(cep));
      await setDoc(
        doc(db, clubesCollection, clubeID),
        { cep: cep },
        { merge: true }
      );
      var cepDoc2 = "comprasEfetuadasEPendentes" + cep;
      const docRef2 = doc(db, clubesCollection, clubeID, epoca, cepDoc2);
      try {
        await auxAdicionaCompraAoClubeAnulada(docRef2, compraId, compra);
        return cep;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }
}

export async function adicionaCompraAoClube2(
  cep,
  compraId,
  compra,
  clubeID,
  epoca
) {
  console.log("adicionaCompraAoClube2");
  var cepDoc = "comprasEfetuadasEPendentes" + cep;
  const docRef = doc(db, clubesCollection, clubeID, epoca, cepDoc);

  try {
    console.log(cep);
    console.log("cep");
    await auxAdicionaCompraAoClube(docRef, compraId, compra);
    return cep;
  } catch (error) {
    console.error("Erro ao adicionar compra ao Clube");
    if (error.message === "too many index entries for entity") {
      cep++;
      store.dispatch(setcep(cep));
      await setDoc(
        doc(db, clubesCollection, clubeID),
        { cep: cep },
        { merge: true }
      );
      var cepDoc2 = "comprasEfetuadasEPendentes" + cep;
      const docRef2 = doc(db, clubesCollection, clubeID, epoca, cepDoc2);
      try {
        await auxAdicionaCompraAoClube(docRef2, compraId, compra);
        return cep;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }
}

export async function adicionaCompraAoClube2Concluida(
  cep,
  compraId,
  compra,
  clubeID,
  epoca
) {
  console.log("adicionaCompraAoClube2");
  var cepDoc = "comprasEfetuadasEPendentes" + cep;
  const docRef = doc(db, clubesCollection, clubeID, epoca, cepDoc);

  try {
    console.log(cep);
    console.log("cep");
    await auxAdicionaCompraAoClubeConcluida(docRef, compraId, compra);
    return cep;
  } catch (error) {
    console.error("Erro ao adicionar compra ao Clube");
    if (error.message === "too many index entries for entity") {
      cep++;
      store.dispatch(setcep(cep));
      await setDoc(
        doc(db, clubesCollection, clubeID),
        { cep: cep },
        { merge: true }
      );
      var cepDoc2 = "comprasEfetuadasEPendentes" + cep;
      const docRef2 = doc(db, clubesCollection, clubeID, epoca, cepDoc2);
      try {
        await auxAdicionaCompraAoClube(docRef2, compraId, compra);
        return cep;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }
}

//TODO depois da adicionaCompraAoClubeV2 estar funcional remover esta função
export async function adicionaCompraAoClube(compraId, compra, clubeID, epoca) {
  const docRef = doc(
    db,
    clubesCollection,
    clubeID,
    epoca,
    "comprasEfetuadasEPendentes1"
  );

  try {
    console.log("A Aidiconar compra ao CLube");
    await auxAdicionaCompraAoClube(docRef, compraId, compra);
    console.log("Compra Adicionada ao clube com sucesso");
    return 1;
  } catch (error) {
    console.log(error);
    console.log("Erro ao adicionar compra ao Clube");
    if (error.message === "too many index entries for entity") {
      return getDoc(docRef).then(async (value) => {
        var numeroDeDocs;
        numeroDeDocs = value.data().numeroDeDocs;
        try {
          var comprasDoc = "comprasEfetuadasEPendentes" + numeroDeDocs;
          const docRef2 = doc(db, clubesCollection, clubeID, epoca, comprasDoc);
          await auxAdicionaCompraAoClube(docRef2, compraId, compra);
          return numeroDeDocs;
        } catch (error) {
          if (error.message === "too many index entries for entity") {
            numeroDeDocs++;
            var comprasDoc2 = "comprasEfetuadasEPendentes" + numeroDeDocs;
            const docRef3 = doc(
              db,
              clubesCollection,
              clubeID,
              epoca,
              comprasDoc2
            );
            await auxAdicionaCompraAoClube(docRef3, compraId, compra);
            return numeroDeDocs;
          }
        }
      });
    }
  }
}

/* "Valor_mensal_a_pagar": 0,
        "Valor_mensal_pago": 0,
        "Valor_pago_kits": 0,
        "Valor_emitido_kits": 0,
        "Valor_pago_Seguros": 0,
        "Valor_emitido_Seguros": 0,
        "Valor_pago_inscricao": 0,
        "Valor_emitido_inscricao": 0, */

export async function atulizaValoresAPagarAoClube(compra, clubeID) {
  try {
    console.log("A atualizar Valores a Pagar ao Clube");
    const docRefV2 = doc(
      db,
      clubesCollection,
      clubeID,
      compra.epoca,
      "pagamentos"
    );
    await setDoc(
      docRefV2,
      {
        Valor_a_pagar: {
          [compra.tipoDaCompra]: increment(compra.valorDaCompra),
        },
      },
      { merge: true }
    );
    console.log("Valores a pagar ao clube");
    return true;
  } catch (e) {
    console.log("erro a atualizar o valor a pagar ao clube");
    console.error(e);
    return null;
  }
}

export async function atulizaValoresAPagarAoClubeConcluida(compra, clubeID) {
  try {
    console.log("A atualizar Valores a Pagar ao Clube");
    const docRefV2 = doc(
      db,
      clubesCollection,
      clubeID,
      compra.epoca,
      "pagamentos"
    );
    await setDoc(
      docRefV2,
      {
        Valor_a_pagar: {
          [compra.tipoDaCompra]: increment(compra.valorDaCompra),
        },
        Valor_pago: {
          [compra.tipoDaCompra]: increment(compra.valorDaCompra),
        },
      },
      { merge: true }
    );
    console.log("Valores a pagar ao clube");
    return true;
  } catch (e) {
    console.log("erro a atualizar o valor a pagar ao clube");
    console.error(e);
    return null;
  }
}

export async function pagarComNumerario(docID, clubeId, epoca, cep) {
  try {
    const pagamentoRef = doc(db, comprasCollection, docID);
    var compraDoc = await getDoc(pagamentoRef);
    var compra = compraDoc.data();

    const atletasRef = doc(db, atletasCollection, compra.atletaId);
    console.log(docID, clubeId, epoca, comprasEfetuadasECanceladas + cep);
    const removePendenteRef = doc(
      db,
      clubesCollection,
      clubeId,
      epoca,
      comprasEfetuadasECanceladas + cep
    );
    const pagamentosRef = doc(
      db,
      clubesCollection,
      clubeId,
      epoca,
      "pagamentos"
    );

    var dateObj = new Date();
    await updateDoc(pagamentoRef, {
      AtualizouPagamentosAoClube: true,
      estado: "Confirmada",
      tipo: "Numerário",
      paidAt: Timestamp.fromDate(dateObj),
    });

    await setDoc(
      pagamentosRef,
      {
        Valor_pago: { [compra.tipoDaCompra]: increment(compra.valorDaCompra) },
      },
      { merge: true }
    );

    await setDoc(
      removePendenteRef,
      { Pendentes: { [docID]: deleteField() } },
      { merge: true }
    );
    await setDoc(
      removePendenteRef,
      {
        Efetuadas: {
          [docID]: {
            atletaDataDeNascimento: compra.atletaDataDeNascimento,
            reference: compra.reference,
            entidade: compra.entidade,
            atletaID: compra.atletaId,
            atletaNome: compra.atletaNome,
            encarregadoEmail: compra.encarregadoEmail,
            mesDaCompra: compra.mesDaCompra,
            paidAt: serverTimestamp(),
            tipoDaCompra: compra.tipoDaCompra,
            valorDaCompra: compra.valorDaCompra,
          },
        },
      },
      { merge: true }
    );

    await setDoc(
      atletasRef,
      { pagamentosEmAtraso: { [docID]: deleteField() } },
      { merge: true }
    );

    await setDoc(
      atletasRef,
      { pagamentosEfetuados: { [docID]: compra } },
      { merge: true }
    );

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function anularPagamento(docID, clubeId, epoca, cep) {
  try {
    const pagamentoRef = doc(db, comprasCollection, docID);
    var compraDoc = await getDoc(pagamentoRef);
    var compra = compraDoc.data();

    const atletasRef = doc(db, atletasCollection, compra.atletaId);
    console.log(docID, clubeId, epoca, comprasEfetuadasECanceladas + cep);
    const removePendenteRef = doc(
      db,
      clubesCollection,
      clubeId,
      epoca,
      comprasEfetuadasECanceladas + cep
    );
    const pagamentosRef = doc(
      db,
      clubesCollection,
      clubeId,
      epoca,
      "pagamentos"
    );

    var dateObj = new Date();
    await updateDoc(pagamentoRef, {
      AtualizouPagamentosAoClube: true,
      estado: "Anulada",
      tipo: "Numerário",
      paidAt: Timestamp.fromDate(dateObj),
    });

    await setDoc(
      pagamentosRef,
      {
        Valor_a_pagar: {
          [compra.tipoDaCompra]: increment(-compra.valorDaCompra),
        },
      },
      { merge: true }
    );

    await setDoc(
      removePendenteRef,
      { Pendentes: { [docID]: deleteField() } },
      { merge: true }
    );
    await setDoc(
      removePendenteRef,
      {
        Anuladas: {
          [docID]: {
            atletaDataDeNascimento: compra.atletaDataDeNascimento,
            reference: compra.reference,
            entidade: compra.entidade,
            atletaID: compra.atletaId,
            atletaNome: compra.atletaNome,
            encarregadoEmail: compra.encarregadoEmail,
            mesDaCompra: compra.mesDaCompra,
            paidAt: serverTimestamp(),
            tipoDaCompra: compra.tipoDaCompra,
            valorDaCompra: compra.valorDaCompra,
          },
        },
      },
      { merge: true }
    );

    await setDoc(
      atletasRef,
      { pagamentosEmAtraso: { [docID]: deleteField() } },
      { merge: true }
    );

    await setDoc(
      atletasRef,
      { pagamentosAnualdos: { [docID]: compra } },
      { merge: true }
    );

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/* export async function anularPagamento(docID) {
  try {
    console.log("docID");
    console.log(docID);
    const pagamentoRef = doc(db, comprasCollection, docID);
    await updateDoc(pagamentoRef, {
      estado: "Anulada",
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
} */

export function veSeExpirou(expiryAt) {
  var dateObj = new Date();
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  month = ("0" + month).slice(-2);
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();

  var newdate = day + "-" + month + "-" + year;
  console.log(expiryAt.substring(0, 2));
  console.log(expiryAt.substring(3, 5));
  console.log(expiryAt.substring(6, 10));

  if (expiryAt.substring(6, 10) <= newdate.substring(6, 10)) {
    if (expiryAt.substring(3, 5) <= newdate.substring(3, 5)) {
      if (expiryAt.substring(0, 2) < newdate.substring(0, 2)) {
        return 1;
      }
    }
  }

  return null;
}

/*
 */
