import { createSlice } from "@reduxjs/toolkit";

export const clubeIdSlice = createSlice({
  name: "clubeId",
  initialState: {
    value: "",
  },
  reducers: {
    setclubeId: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setclubeId } = clubeIdSlice.actions;

export const selectclubeId = (state) => state.clubeId.value;

export default clubeIdSlice.reducer;
