import { createSlice } from "@reduxjs/toolkit";

export const valoresAPagarSlice = createSlice({
  name: "valoresAPagar",
  initialState: {
    value: "",
  },
  reducers: {
    setvaloresAPagar: (state, action) => {
      state.value = action.payload;
    },
    resetValoresAPagar: (state) => {
        state.value = [];
    }
  },
});

// Action creators are generated for each case reducer function
export const { setvaloresAPagar,resetValoresAPagar } = valoresAPagarSlice.actions;

export const selectvaloresAPagar = (state) => state.valoresAPagar.value;

export default valoresAPagarSlice.reducer;
