import { createSlice } from "@reduxjs/toolkit";

export const pagamentosTipoSlice = createSlice({
  name: "pagamentosTipo",
  initialState: {
    value: [],
  },
  reducers: {
    setpagamentosTipo: (state, action) => {
      action.payload.forEach((element) =>
        state.value.push(JSON.stringify(element))
      );
    },
    resetPagamentos: (state,action) => {
        state.value = [];
    }
  },
});

export function pagamentoToJson(listaMapStrings) {
    var listaJson= []
    for (const key in listaMapStrings) {
       listaJson.push(JSON.parse(listaMapStrings[key]));
    }
    return listaJson;
}

// Action creators are generated for each case reducer function
export const { setpagamentosTipo, resetPagamentos } = pagamentosTipoSlice.actions;

export const selectpagamentosTipo = (state) => state.pagamentosTipo.value;

export default pagamentosTipoSlice.reducer;
