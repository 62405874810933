import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setpagamentosEmFalta } from "../../reducers/pagamentos_falta_reducer";

function DashBoardCard(props) {
  const [key, setKey] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOnClickFalta = useCallback(
    () => navigate("/dashboard/pagamentosEmFalta", { replace: false }),
    [navigate]
  );

  function navega(tipo) {
    dispatch(setpagamentosEmFalta(tipo));
    handleOnClickFalta();
  }

  return (
    // <div className="p-3 ">
    //   <Card className=" font-face-gm ">
    //     <Card.Header style={{ fontSize: 30,backgroundColor:"#1B55EC",color:"#FFF",fontWeight:"bold" }}>{key}</Card.Header>
    //     <Card.Body className="m-4 p-sm-3 p-md-4 p-lg-4 rounded-3 " style={{backgroundColor:"#F0F4FC"}}>
    //       <Card.Text style={{color:"#416CDA"}}><Checkbox icon={<img src={redIcon} alt="" />} disabled/> Valor total a Receber: <strong style={{fontWeight:900}}>{value}€</strong> </Card.Text>
    //       <Card.Text style={{color:"#416CDA"}}><Checkbox icon={<img src={greenIcon} alt="" /> } disabled/>Valor total Recebido: <strong>{valuePago}€</strong> </Card.Text>
    //     </Card.Body>
    //     <Button className="btn m-3 rounded-5" onClick={() => navega(key)} variant="primary" style={{width:"fit-content",float:"right", alignSelf:"flex-end"}}>
    //       Ver Pagamentos em falta <i className="fas fa-chevron-right"></i>
    //     </Button>
    //   </Card>
    // </div>
    <div className="grid_cards_hero_card">
      <div className="grid_cards_hero_card_title">
        <h3>{props.nome}</h3>
      </div>

      <div className="grid_cards_hero_card_content">
        <div className="valor_recebido">
          <div className="valor">
            <div className="icon">
              <svg
                width="40"
                height="40"
                viewBox="0 0 24 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14" cy="10" r="10" fill="#1B55EC" />
                <path
                  d="M1.075 17L0 15.8974L7.3 8.4359L11.475 12.7179L17.5 6.53846H14.325V5H20V10.8205H18.525V7.66667L11.45 14.9231L7.275 10.641L1.075 17Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="text">
              <h4>{props.valorPago}€</h4>
            </div>
          </div>

          <p id="recebidos">recebidos</p>
        </div>

        <hr className="separador"></hr>

        {/* SE VALUEPAGO == VALUE */}
        {props.valorAPagar === props.valorPago ? (
          <div className="nao_tem_a_receber">
            <div className="valor">
              <div className="icon">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 24 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="14" cy="10" r="10" fill="#DF1616" />
                  <path
                    d="M1.075 17L0 15.8974L7.3 8.4359L11.475 12.7179L17.5 6.53846H14.325V5H20V10.8205H18.525V7.66667L11.45 14.9231L7.275 10.641L1.075 17Z"
                    fill="white"
                  />
                </svg>
              </div>

              <div className="text">
                <h4>{props.valorAPagar}€</h4>
              </div>
            </div>

            <p id="recebidos">A receber</p>
          </div>
        ) : (
          <div className="valor_por_receber">
            <div className="valor">
              <div className="icon">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 24 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="14" cy="10" r="10" fill="#DF1616" />
                  <path
                    d="M1.075 17L0 15.8974L7.3 8.4359L11.475 12.7179L17.5 6.53846H14.325V5H20V10.8205H18.525V7.66667L11.45 14.9231L7.275 10.641L1.075 17Z"
                    fill="white"
                  />
                </svg>
              </div>

              <div className="text">
                <h4>{props.valorAPagar}€</h4>
              </div>
            </div>
            <p id="recebidos">A receber</p>
          </div>
        )}
      </div>
      <div style={{ alignItem: "center", textAlign: "center" }}>
        <p>
          <Button onClick={() => navega(props.nome)}>
            Pagamentos &nbsp;
            <i className="fas fa-chevron-right"></i>
          </Button>
        </p>
      </div>
    </div>
  );
}

export default DashBoardCard;
