import { createSlice } from "@reduxjs/toolkit";

export const atletaPerfilSlice = createSlice({
  name: "atletaPerfil",
  initialState: {
    value: "",
  },
  reducers: {
    setAtleta: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAtleta } = atletaPerfilSlice.actions;

export const selectAtletaPerfil = (state) => state.atletaPerfil.value;

export default atletaPerfilSlice.reducer;
