import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  doc,
  query,
  updateDoc,
  getFirestore,
  setDoc,
  limit,
  arrayUnion,
  onSnapshot,
} from "firebase/firestore";
import { atletasCollection, clubesCollection } from "../consts/constants";
import db from "../firebase.config";

export async function addPagamentosEmAtraso(compraId, compra) {
  try {
    console.log("estou nos pagamentos em atraso");
    const atletaRef = doc(db, atletasCollection, compra.atletaId);
    if (compra.estado === "Pendente") {
      if (compra.classeDaCompra === "Mensalidade") {
        await setDoc(
          atletaRef,
          {
            mensalidadeAtiva: true,
            valorDaMensalidade: compra.valorDaCompra,
            pagamentosEmAtraso: { [compraId]: compra.toJson() },
          },
          { merge: true }
        );
      } else {
        await setDoc(
          atletaRef,
          {
            pagamentosEmAtraso: { [compraId]: compra.toJson() },
          },
          { merge: true }
        );
      }
    }
    if (compra.estado === "Anulada") {
      await setDoc(
        atletaRef,
        {
          pagamentosAnualdos: { [compraId]: compra.toJson() },
        },
        { merge: true }
      );
    }
    if (compra.estado === "Confirmada") {
      if (compra.tipoDaCompra === "Mensalidade") {
        await setDoc(
          atletaRef,
          {
            mensalidadeAtiva: true,
            valorDaMensalidade: compra.valorDaCompra,
            pagamentosEfetuados: { [compraId]: compra.toJson() },
          },
          { merge: true }
        );
      } else {
        await setDoc(
          atletaRef,
          {
            pagamentosEfetuados: { [compraId]: compra.toJson() },
          },
          { merge: true }
        );
      }
    }

    console.log("final dos pagamentos em atraso");
    return true;
  } catch (e) {
    console.error(e.toString());
    return null;
  }
}

export async function getEncarregadoData(encID) {
  try {
    const docRef = doc(db, "encarregados", encID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap;
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function fetchAtletasNaoInscrtos(clubeId, epoca) {
  var docRef = doc(db, clubesCollection, clubeId, epoca, "atletas");
  const unsub = onSnapshot(docRef, (doc) => {
    var listaAuxId = [];
    for (const key in doc.data().Inscritos_nao) {
      listaAuxId.push(key);
    }
    return listaAuxId;
  });
}
