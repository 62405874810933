import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectclubeId } from "../../reducers/clube_id_reducer";
import { selectClube } from "../../reducers/clube_reducer";
import { selectEpoca } from "../../reducers/epoca_reducer";
import { selectnumerosCamisola } from "../../reducers/numeros_camisola_reducer";
import {
  fetchCamisolaDataPorAno,
  verNumerosDacamisolaDisponiveis,
} from "../../services/atletaServices/atleta_editar_dados_services";

function NumerosDialog(props) {
  const [smShow, setSmShow] = useState(false);
  const [lista, setLista] = useState([]);
  const anoDeNascimento = props.dataDeNascimento.substring(0,4);
  var listaAux = [];

  var camisolaNRData = useSelector(selectnumerosCamisola)
  var camisolaNRJson = JSON.parse(camisolaNRData);

  if(camisolaNRJson[anoDeNascimento] !== "undefined"  ) {
    for (var key in camisolaNRJson[anoDeNascimento]) {
      var jsonData = {};
      jsonData.nrCamisola = key;
      jsonData.nome = camisolaNRJson[anoDeNascimento][key][0]
      listaAux.push(jsonData);
    }
  }


  useEffect(() => {
    setLista(listaAux);
  }, []);

  return (
    <>
      <Button onClick={() => setSmShow(true)} className="me-2">
        {props.title}
      </Button>

      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lista.length === 0 ? (
            <div>Todos os números estão disponíveis</div>
          ) : (
            lista.map((elem, index) => {
              return (
                <Tooltip key={index} title={elem.nome}>
                  <span>{" " + elem.nrCamisola + ", "}</span>
                </Tooltip>
              );
            })
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NumerosDialog;
