import { createSlice } from "@reduxjs/toolkit";

export const emailSlice = createSlice({
  name: "email",
  initialState: {
    value: "",
  },
  reducers: {
    setemail: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setemail } = emailSlice.actions;

export const selectemail = (state) => state.email.value;

export default emailSlice.reducer;
