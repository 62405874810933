import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDeDRe1Ll84nwKGQgk0i4XwT1iSPIZ855Y",
  authDomain: "macrofoot-75822.firebaseapp.com",
  projectId: "macrofoot-75822",
  storageBucket: "macrofoot-75822.appspot.com",
  messagingSenderId: "302604794879",
  appId: "1:302604794879:web:600e6a8b0a90e82b0085ca",
  measurementId: "G-88ZQWPRXPC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { auth };

export default db;
