import { doc, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { atletasCollection } from "../../consts/constants";
import db from "../../firebase.config";

export async function addExameMedico(clube,email, atleta, exameMedico, atletaId) {
try {

    const storage = getStorage();
    
console.log("bp1");
var referencePath =
"/" + clube + "/inscricaoV2/" + email + "/Exame medico " +atleta ;
const exameMedRef = ref(storage, referencePath);
console.log("bp2");
console.log(referencePath);
await uploadBytes(exameMedRef, exameMedico).then((snapshot) => {
    console.log('Uploaded a blob or file!');
  });

var downloadURL = await getDownloadURL(exameMedRef);
console.log("bp3");
var docRef = doc(db, atletasCollection, atletaId );
await setDoc(docRef,{exameMedico: downloadURL }, {merge: true} );
} catch (error) {
    console.log("error");
    console.error(error);
}
    // Create a root reference



}

async function uploadFotoDOCC(imagem, userEmail, clube, nome) {
    try {
      console.log("bp0500");
      var storageRef = getStorage();
      var referencePath =
        "/" + clube + "/inscricaoV2" + "/" + userEmail + "/" + nome;
      var iamgeRef = storageRef.child(referencePath);
      var downloadURL = iamgeRef.put(imagem).then((snapshot) => {
        console.log("Uploaded a blob or file!");
        return snapshot.ref.getDownloadURL();
      });
  
      return downloadURL;
    } catch (error) {
      console.log("error");
      console.log(error);
      return null;
    }
  }

