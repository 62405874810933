import { combineReducers, configureStore } from "@reduxjs/toolkit";
import atletasReducer from "../src/reducers/ateltas_reducer";
import atletaPerfilReducer from "./reducers/atleta_perfil_reducer";
import encarregadoReducer from "./reducers/encarregado_reducer";
import atletaReducer from "./reducers/atleta_id_reducer";
import encarregadoIdReducer from "./reducers/encarregado_id_reducer";
import authReducer from "./reducers/auth_reducer";
import clubeReducer from "./reducers/clube_reducer";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import epocaReducer from "./reducers/epoca_reducer";
import emailReducer from "./reducers/email_reducer";
import pagamentosFaltaReducer from "./reducers/pagamentos_falta_reducer";
import clubeIdReducer from "./reducers/clube_id_reducer";
import pagamentosTipoReducer from "./reducers/pagamentos_tipo_reducer";
import mensalidadesReducer from "./reducers/mensalidades_recuder";
import valoresPagosReducer from "./reducers/valores_pagos_reducer";
import valoresAPagarPeducer from "./reducers/valores_a_pagar_reducer";
import clubeDataReducer from "./reducers/clubeData_reducer";
import comprasReducer from "./reducers/compras_efetuadas_reduce";
import comprasPendentesReducer from "./reducers/compras_pendentes_reducer";
import cepReducer from "./reducers/cep_reducer";
import numerosCamisolaReducer from "./reducers/numeros_camisola_reducer";

const reducers = combineReducers({
  numerosCamisola: numerosCamisolaReducer,
  cep: cepReducer,
  comprasPendentes: comprasPendentesReducer,
  compras: comprasReducer,
  clubeData: clubeDataReducer,
  valoresPagos: valoresPagosReducer,
  valoresAPagar: valoresAPagarPeducer,
  mensalidade: mensalidadesReducer,
  pagamentosTipo: pagamentosTipoReducer,
  clubeId: clubeIdReducer,
  pagamentosEmFalta: pagamentosFaltaReducer,
  atletas: atletasReducer,
  atletaPerfil: atletaPerfilReducer,
  encarregadoPerfil: encarregadoReducer,
  atletaId: atletaReducer,
  encarregadoId: encarregadoIdReducer,
  auth: authReducer,
  clube: clubeReducer,
  epoca: epocaReducer,
  email: emailReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export default store;
