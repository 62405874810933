import { createSlice } from "@reduxjs/toolkit";

export const comprasPendentesSlice = createSlice({
  name: "comprasPendentes",
  initialState: {
    value: [],
  },
  reducers: {
    setcomprasPendentes: (state, action) => {
      action.payload.forEach((element) => state.value.push(element));
    },
    resetcomprasPendentes: (state) => {
      state.value = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setcomprasPendentes, resetcomprasPendentes } =
  comprasPendentesSlice.actions;

export const selectcomprasPendentes = (state) => state.comprasPendentes.value;

export default comprasPendentesSlice.reducer;
