import { Timestamp } from "firebase/firestore";
import { mesesCompleto } from "../../consts/constants";
import Compra from "../../models/compra";
import {
  adicionaCompra,
  adicionaCompraAoClube,
  adicionaCompraAoClube2,
  atualizarNumeroDoCEPNaCompra,
} from "../compras_services";
import { addPagamentosEmAtraso } from "../encarregados_services";
import { criaEmailBOdy, enviarEmailPagamentos } from "../mail_services";
import { criaReferenciaMultibancoViaAPI } from "../multivanco_services";

export async function enviarPagamentosAtleta(
  clube,
  epoca,
  mapNomeValor,
  atletaData,
  enviarEmail,
  atletaID,
  clubeID,
  mapMesesEscolhidos,
  cepRed
) {
  try {
    var htmlBody =
      " <style>" +
      ".text {" +
      " align-self: flex-end;" +
      "}" +
      ".image {" +
      " width: 120px;" +
      " height: 120px;" +
      "}" +
      ".paragraph {" +
      "display: flex;" +
      "}" +
      "</style>";

    console.log("mapNomeValor");
    console.log(mapNomeValor);

    /* for (let index = 0; index < mapNomeValor.size; index++) {
      
      var key = Array.from(mapNomeValor.keys())[index];
      var value = mapNomeValor.get(key); */
    for (let pair of mapNomeValor) {
      var [key, value] = pair;
      console.log("pair");

      var multibancoData = await criaReferenciaMultibancoViaAPI(value);

      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1;
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();

      var compra = new Compra();
      compra.anoCreatedAt = year;
      compra.diaCreatedAt = day;
      compra.mesCreatedAt = month;
      compra.createdAt = Timestamp.fromDate(dateObj);
      compra.clube = clube;
      compra.encarregadoEmail = atletaData.emailEncarregado;
      compra.encarregadoNome = atletaData.nomeCompletoEncarregado;
      compra.encarregadoDataDeNascimento =
        atletaData.dataDeNascimentoEncarregado;
      compra.atletaNome = atletaData.nomeCompleto;
      compra.atletaId = atletaID;
      compra.atletaDataDeNascimento = atletaData.dataDeNascimento;
      compra.tipoDaCompra = key;
      compra.estado = "Pendente";
      compra.valorDaCompra = parseFloat(value);
      compra.entidade = multibancoData.Entity;
      compra.orderID = multibancoData.OrderId;
      compra.reference = multibancoData.Reference;
      compra.requestId = multibancoData.RequestId;
      compra.status = multibancoData.Status;
      compra.expiryDate = multibancoData.ExpiryDate;
      compra.epoca = epoca;
      compra.cep = cepRed;

      var compraAdcio;
      var emailData;
      var cep;

      console.log("compra");
      console.log(compra);

      if (typeof mapMesesEscolhidos[key] === "undefined") {
        // enviar pagamentos anuais
        compra.classeDaCompra = "Anual";
        compra.mesDaCompra = null;

        compraAdcio = await adicionaCompra(compra, clubeID);
        cep = await adicionaCompraAoClube2(
          cepRed,
          compraAdcio.id,
          compra,
          clubeID,
          epoca
        );
        compra.cep = cep;
        await atualizarNumeroDoCEPNaCompra(compraAdcio.id, cep);
        await addPagamentosEmAtraso(compraAdcio.id, compra);

        if (enviarEmail) {
          emailData = criaEmailBOdy(
            compra.tipoDaCompra,
            compra.valorDaCompra.toString(),
            compra.entidade,
            compra.reference,
            compra.expiryDate
          );
          htmlBody = htmlBody + emailData;
        }
      }
      if (typeof mapMesesEscolhidos[key] !== "undefined") {
        for (const indexNrMes in mapMesesEscolhidos[key]) {
          compra.classeDaCompra = "Mensalidade";
          compra.mesDaCompra = mapMesesEscolhidos[key][indexNrMes];

          compraAdcio = await adicionaCompra(compra, clubeID);
          cep = await adicionaCompraAoClube2(
            cepRed,
            compraAdcio.id,
            compra,
            clubeID,
            epoca
          );

          compra.cep = cep;
          await atualizarNumeroDoCEPNaCompra(compraAdcio.id, cep);
          await addPagamentosEmAtraso(compraAdcio.id, compra);

          if (enviarEmail) {
            emailData = criaEmailBOdy(
              compra.tipoDaCompra +
                " do mês de " +
                mesesCompleto[mapMesesEscolhidos[key][indexNrMes]],
              compra.valorDaCompra.toString(),
              compra.entidade,
              compra.reference,
              compra.expiryDate
            );
            htmlBody = htmlBody + emailData;
          }
        }
      }
    }
    if (enviarEmail) {
      await enviarEmailPagamentos(
        compra.encarregadoEmail,
        "Pagamentos ao " + clube,
        htmlBody
      );
    }
    //Ativa mensalidade no Encarregado
  } catch (error) {
    console.log("error");
    console.log(error);
  }
}
