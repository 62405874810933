import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import ReferenciaDialog from "../../components/dialog/referencia_dialog";
import db from "../../firebase.config";
import { selectAtletaPerfil } from "../../reducers/atleta_perfil_reducer";
import { selectClube } from "../../reducers/clube_reducer";
import { selecEncarregadoPerfil } from "../../reducers/encarregado_reducer";
import { veSeExpirou } from "../../services/compras_services";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Banner from "../../components/bannerPaginas/Banner";

function AtletasAtrasados() {
  const isEven = (num) => num % 2 === 0;
  var clube = useSelector(selectClube);
  const [pagamentos, setPagamentos] = useState([]);

  const encarregadoString = useSelector(selecEncarregadoPerfil);
  const encarregado = JSON.parse(encarregadoString);
  const atletaID = encarregado.idsAtltasAssociados[0];

  console.log(encarregadoString);

  async function fetchPagamentos() {
    console.log("fetching From Firebase");

    const mensalidadeQuery = query(
      collection(db, "compras"),
      where("clube", "==", clube),
      where("atletaId", "==", atletaID),
      where("estado", "==", "Pendente")
    );

    onSnapshot(mensalidadeQuery, (querySnapshot) => {
      var getPagamentos = [];
      querySnapshot.forEach((doc) => {
        getPagamentos.push(doc);
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
          }
          if (change.type === "modified") {
          }
          if (change.type === "removed") {
          }
        });
      });
      setPagamentos(getPagamentos);
    });
  }

  useEffect(() => {
    fetchPagamentos();
  }, []);

  function sortByNome() {}

  function sortByDataDeNascimento() {}

  function sortByPagamentosEmAtraso() {}

  return (
    <div className="app_container_main_section">
      <Banner />
      <div className="app_container_main_section_content">
        <div className="heading">
          <h3>Pagamentos em Atraso</h3>
        </div>

        {/* Tabela */}
        <div className="tabela_generica w-100 table-responsive">
          <Table className="table  table-hover ">
            <TableHead>
              <TableRow>
                <th scope="col"> Tipo </th>
                <th scope="col"> Valor</th>
                <th scope="col"> Estado</th>
                <th scope="col"> Expira em:</th>
                <th>Opcoes:</th>
              </TableRow>
            </TableHead>

            <TableBody>
              {pagamentos.map((pagamentoI, index) => {
                const pagamento = pagamentoI.data();
                console.log("pagamento");
                console.log(pagamento);
                return (
                  <TableRow
                    key={index.toString() + "Row"}
                    className={index % 2 === 0 ? "blue" : "white"}
                  >
                    <TableCell>
                      <p>{pagamento.tipoDaCompra}</p>
                    </TableCell>

                    <TableCell>
                      <p>{pagamento.valorDaCompra}</p>
                    </TableCell>

                    <TableCell>
                      <p>{pagamento.estado}</p>
                    </TableCell>

                    <TableCell>
                      <p>
                        {veSeExpirou(pagamento.expiryDate) === 1
                          ? "Expirou"
                          : pagamento.expiryDate}
                      </p>
                    </TableCell>

                    <TableCell>
                      <ReferenciaDialog
                        tipoDaCompra={pagamento.tipoDaCompra}
                        encarregadoEmail={pagamento.encarregadoEmail}
                        estado={pagamento.estado}
                        valorRef={pagamento.valorDaCompra}
                        compraID={pagamentoI.id}
                        referencia={" " + pagamento.reference}
                        entidade={" " + pagamento.entidade}
                        valor={" " + pagamento.valorDaCompra + "€"}
                        expira={" " + pagamento.expiryDate}
                      ></ReferenciaDialog>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default AtletasAtrasados;
