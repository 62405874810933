import { createSlice } from "@reduxjs/toolkit";

export const atletaIdSlice = createSlice({
  name: "atletaId",
  initialState: {
    value: "",
  },
  reducers: {
    setAtletaId: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAtletaId } = atletaIdSlice.actions;

export const selectAtletaId = (state) => state.atletaId.value;

export default atletaIdSlice.reducer;
