//main color color: #1B55EC;
export const mainColor = "#1B55EC";
export const atletasCollection = "atletasV2";
export const comprasCollection = "comprasV2";
export const clubesCollection = "clubesV2";
export const inscricoesCollection = "inscricoesV2";
export const camisolaNrDocument = "camisolanr";
export const comprasEfetuadasECanceladas = "comprasEfetuadasEPendentes"

export const listaDeEpocas = ["2022-2023"];
export const numerosDisponiveisParaMBExpiry = () => {
  var listaAux= [];
  for (let index = 0; index < 31; index++) {
    listaAux.push(index);
  }
  return listaAux;
}

export var mesesCompleto = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
