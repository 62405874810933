import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { clubesCollection } from "../consts/constants";
import db, { auth } from "../firebase.config";

export const logInWithEmailAndPassword = async (email, password) => {
  try {
    var user = await signInWithEmailAndPassword(auth, email, password);
    var clube;
    var epoca;
    const q = query(
      collection(db, clubesCollection),
      where("email", "==", email),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      clube = doc.data().clube;
      epoca = doc.data().epoca;
      console.log(doc.id, " => ", doc.data());
    });
    console.log(user);
    return [clube, epoca];
  } catch (err) {
    console.log("err");
    console.error(err);
  }
};

export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const logout = () => {
  signOut(auth).then(window.localStorage.clear());
};

export async function resetPassword(email) {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      logout();
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
}
