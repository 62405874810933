import { createSlice } from "@reduxjs/toolkit";

export const valoresPagosSlice = createSlice({
  name: "valoresPagos",
  initialState: {
    value: "",
  },
  reducers: {
    setvaloresPagos: (state, action) => {
      state.value = action.payload;
    },
    resetValoresPagos: (state) => {
        state.value = [];
    }
  },
});

// Action creators are generated for each case reducer function
export const { setvaloresPagos,resetValoresPagos } = valoresPagosSlice.actions;

export const selectvaloresPagos = (state) => state.valoresPagos.value;

export default valoresPagosSlice.reducer;
