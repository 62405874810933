import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import greenIcon from "../../assets/icons/greenIconSVG.svg";
import redIcon from "../../assets/icons/redIconSVG.svg";
import { Checkbox, FormControlLabel } from "@mui/material";
import { enviarPagamentosAtleta } from "../../services/atletaServices/enviar_pagamentos";
import {
  enviarPagamentos,
  veSeAsMensalidadesTempeloMenosUmMes,
} from "../../services/inscricao_services";
import { useSelector } from "react-redux";
import { selectClube } from "../../reducers/clube_reducer";
import { selectEpoca } from "../../reducers/epoca_reducer";
import "./dialog_component.css";
import { Col, Container, Row } from "react-bootstrap";
import { selectpagamentosTipo } from "../../reducers/pagamentos_tipo_reducer";
import { selectmensalidade } from "../../reducers/mensalidades_recuder";
import { selectclubeId } from "../../reducers/clube_id_reducer";
import { selectcep } from "../../reducers/cep_reducer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  var cepRed = useSelector(selectcep);
  var clube = useSelector(selectClube);
  var clubeID = useSelector(selectclubeId);
  var epoca = useSelector(selectEpoca);
  var pagamentoTipo = useSelector(selectpagamentosTipo);
  var mensalidades = useSelector(selectmensalidade);
  var enviarEmail = true;
  const [open, setOpen] = React.useState(false);
  const [isHiden, setIsHiden] = useState(false);

  var mapValor = { Valor: {} };
  var mapValorMensalidades = { Valor_mensalidades: {} };

  var meses = [
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ",
  ];

  var mapMesesEscolhidos = {};

  function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  function adicionaMesesEscolhidos(numeroDoMes, nomeDaMensalidade) {
    document.getElementById("focusablePargraph").hidden = true;
    if (typeof mapMesesEscolhidos[nomeDaMensalidade] === "undefined") {
      mapMesesEscolhidos[nomeDaMensalidade] = [];
    }

    if (!mapMesesEscolhidos[nomeDaMensalidade].includes(numeroDoMes)) {
      mapMesesEscolhidos[nomeDaMensalidade].push(numeroDoMes);
      document.getElementById(
        nomeDaMensalidade + numeroDoMes.toString() + "btn_mes"
      ).style = "background-color: blue; color: white";
    } else {
      removeItemAll(mapMesesEscolhidos[nomeDaMensalidade], numeroDoMes);
      document.getElementById(
        nomeDaMensalidade + numeroDoMes.toString() + "btn_mes"
      ).style = "background-color: white; color: blue";
    }
    console.log(mapMesesEscolhidos);
  }

  async function enviarPagamentosLoading() {
    setIsHiden(true);
    document.getElementById("focusablePargraph").hidden = true;
    if (veSeAsMensalidadesTempeloMenosUmMes(mapMesesEscolhidos)) {
      await enviarPagamentosAtleta(
        clube,
        epoca,
        mapNomeValor,
        props.atleta,
        enviarEmail,
        props.atletaID,
        clubeID,
        mapMesesEscolhidos,
        cepRed
      );

      setIsHiden(false);
      handleClose();
    } else {
      document.getElementById("focusablePargraph").hidden = false;
      document.getElementById("focusablePargraph").focus();
      setIsHiden(false);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  var mapNomeValor = new Map();

  function handleChange2(e) {
    const isChecked = e.target.checked;
    enviarEmail = isChecked;
    console.log(isChecked);
  }

  function handleChange(e, nome, pagamento) {
    const isChecked = e.target.checked;
    if (isChecked) {
      mapNomeValor.set(nome, pagamento);
    } else {
      mapNomeValor.delete(nome);
    }
    console.log(mapNomeValor);
  }

  function handleChangeMensalidade(e, nome, pagamento) {
    const isChecked = e.target.checked;
    if (isChecked) {
      document.getElementById("meses" + nome).hidden = false;
      mapNomeValor.set(nome, pagamento);
      mapMesesEscolhidos[nome] = [];
    } else {
      document.getElementById("meses" + nome).hidden = true;
      mapNomeValor.delete(nome);
      delete mapMesesEscolhidos[nome];
    }
    console.log(mapNomeValor);
  }

  const renderDialogPagamentos = () => {
    return (
      <div>
        <button class="btn btn-primary" onClick={handleClickOpen}>
          Enviar pagamentos
        </button>
        <BootstrapDialog onClose={handleClose} open={open}>
          <BootstrapDialogTitle
            id=""
            onClose={handleClose}
            style={{
              padding: "2rem",
              paddingTop: "3rem",
              color: "#1B55EC",
              margin: 0,
              fontWeight: 700,
              fontSize: 24,
              maxWidth: "400px",
              lineHeight: 1,
            }}
          >
            Escolher pagamentos a enviar
          </BootstrapDialogTitle>

          <DialogContent>
            <div className="check_box_info d-flex align-items-center">
              <Checkbox
                icon={<img src={redIcon} alt="" />}
                checkedIcon={<img src={greenIcon} alt="" />}
                sx={{
                  color: "#C83953",
                  "&.Mui-checked": {
                    color: "#4DC839",
                  },
                }}
                defaultChecked
                onChange={(e) => handleChange2(e)}
              />
              <p style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}>
                Enviar pagamentos por email
              </p>
            </div>

            <hr />
            <p
              style={{
                padding: "1rem",
                color: "#1B55EC",
                margin: 0,
                fontWeight: 500,
                fontSize: 20,
                maxWidth: "400px",
                lineHeight: 1,
              }}
            >
              Pagamentos Anuais
            </p>
            {pagamentoTipo.map((pagamento, index) => {
              var keyAux;
              var valueAux;
              for (const key in JSON.parse(pagamento)) {
                keyAux = key;
                valueAux = JSON.parse(pagamento)[key];
                mapValor.Valor[keyAux] = valueAux;
              }

              return (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center gap-2"
                >
                  <div className="check_box_info d-flex align-items-center">
                    <Checkbox
                      icon={<img src={redIcon} alt="" />}
                      checkedIcon={<img src={greenIcon} alt="" />}
                      sx={{
                        color: "#C83953",
                        "&.Mui-checked": {
                          color: "#4DC839",
                        },
                      }}
                      onChange={(e) =>
                        handleChange(e, keyAux, valueAux.toString())
                      }
                    />
                    <p style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}>
                      {keyAux}
                    </p>
                  </div>

                  <div
                    className="check_box_border w-100 m-0 p-0"
                    style={{
                      borderBottomStyle: "dotted",
                      borderBottomWidth: "2px",
                      borderColor: "#EDEDED",
                    }}
                  ></div>

                  <div className="check_box_preco">
                    <p style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}>
                      {valueAux.toString() + "€"}
                    </p>
                  </div>
                </div>
              );
            })}
            <hr />
            <p
              style={{
                padding: "1rem",

                color: "#1B55EC",
                margin: 0,
                fontWeight: 500,
                fontSize: 20,
                maxWidth: "400px",
                lineHeight: 1,
              }}
            >
              Pagamentos Mensais
            </p>
            {mensalidades.map((mensalidade, index) => {
              var keyAux;
              var valueAux;
              for (const key in JSON.parse(mensalidade)) {
                keyAux = key;
                valueAux = JSON.parse(mensalidade)[key];
                mapValorMensalidades.Valor_mensalidades[keyAux] = valueAux;
              }
              console.log(mapNomeValor);
              console.log(keyAux);

              return (
                <div className="col" key={index.toString() + "Row"}>
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center gap-2"
                  >
                    <div className="check_box_info d-flex align-items-center">
                      <Checkbox
                        icon={<img src={redIcon} alt="" />}
                        checkedIcon={<img src={greenIcon} alt="" />}
                        sx={{
                          color: "#C83953",
                          "&.Mui-checked": {
                            color: "#4DC839",
                          },
                        }}
                        onChange={(e) =>
                          handleChangeMensalidade(
                            e,
                            keyAux,
                            valueAux.toString()
                          )
                        }
                      />
                      <p
                        style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}
                      >
                        {keyAux}
                      </p>
                    </div>

                    <div
                      className="check_box_border w-100 m-0 p-0"
                      style={{
                        borderBottomStyle: "dotted",
                        borderBottomWidth: "2px",
                        borderColor: "#EDEDED",
                      }}
                    ></div>

                    <div className="check_box_preco">
                      <p
                        style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}
                      >
                        {valueAux.toString() + "€"}
                      </p>
                    </div>
                  </div>
                  <Container
                    hidden={true}
                    id={"meses" + keyAux}
                    style={{
                      minWidth: "400px",
                      maxWidth: "400px",
                      textAlign: "center",
                      padding: "10px 20px",
                    }}
                  >
                    <Row>
                      <p>Escolher Mês/Meses da mensalidade</p>
                    </Row>
                    <Row>
                      {meses.map((elem, index) => {
                        return (
                          <Col key={index} sm={3}>
                            <Button
                              id={keyAux + index.toString() + "btn_mes"}
                              onClick={() => {
                                adicionaMesesEscolhidos(index, keyAux);
                              }}
                            >
                              {elem}
                            </Button>
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>
                </div>
              );
            })}
            <p
              hidden={true}
              id="focusablePargraph"
              tabIndex={0}
              style={{
                margin: "00px",
                maxWidth: "400px",
                textAlign: "center",
                color: "#BB0000",
              }}
            >
              Tem que escolher pelo menos um mês para as mensalidades escolhidas
            </p>
          </DialogContent>

          <Button
            style={{ color: "white" }}
            className="btn btn-primary p-3 px-0  m-3 mt-5"
            hidden={isHiden}
            autoFocus
            onClick={() => enviarPagamentosLoading()}
          >
            Enviar
          </Button>
          <Button disabled={isHiden} hidden={!isHiden}>
            <span
              class="btn-primary p-3 m-5 spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading
          </Button>
        </BootstrapDialog>
      </div>
    );
  };

  return <>{renderDialogPagamentos()}</>;
}

/* import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import greenIcon from "../../assets/icons/greenIconSVG.svg";
import redIcon from "../../assets/icons/redIconSVG.svg";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { selectClube } from "../../reducers/clube_reducer";
import { selectEpoca } from "../../reducers/epoca_reducer";
import "./dialog_component.css";
import { Col, Container, Row } from "react-bootstrap";
import { enviarPagamentosAtleta } from "../../services/atletaServices/enviar_pagamentos";
import { selectclubeId } from "../../reducers/clube_id_reducer";
import { selectpagamentosTipo } from "../../reducers/pagamentos_tipo_reducer";
import { selectmensalidade } from "../../reducers/mensalidades_recuder";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogsAtleta(props) {
  var clube = useSelector(selectClube);
  var epoca = useSelector(selectEpoca);
  var clubeID = useSelector(selectclubeId);
  const [enviarEmail, setenviarEmail] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [isHiden, setIsHiden] = useState(false);

  var pagamentoTipo = useSelector(selectpagamentosTipo);
  var mensalidades = useSelector(selectmensalidade);
  var mapValor = { Valor: {} };
  var mapValorMensalidades = { Valor_mensalidades: {} };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  var mapNomeValor = new Map();

  function handleChange(e, nome, pagamento) {
    const isChecked = e.target.checked;

    if (isChecked) {
      mapNomeValor.set(nome, pagamento);
      console.log(mapNomeValor);
    } else {
      mapNomeValor.delete(nome);
      console.log(mapNomeValor);
    }
  }

  function handleChange2(e) {
    const isChecked = e.target.checked;
    setenviarEmail(isChecked);
    console.log(isChecked);
  }

  return (
    <div>
      <button class="btn btn-primary" onClick={handleClickOpen}>
        Enviar pagamentos
      </button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id=""
          onClose={handleClose}
          style={{
            padding: "2rem",
            paddingTop: "3rem",
            color: "#1B55EC",
            margin: 0,
            fontWeight: 700,
            fontSize: 24,
            maxWidth: "400px",
            lineHeight: 1,
          }}
        >
          Escolher pagamentos a enviar
        </BootstrapDialogTitle>

        <DialogContent>
          <div className="check_box_info d-flex align-items-center">
            <Checkbox
              icon={<img src={redIcon} alt="" />}
              checkedIcon={<img src={greenIcon} alt="" />}
              sx={{
                color: "#C83953",
                "&.Mui-checked": {
                  color: "#4DC839",
                },
              }}
              checked={enviarEmail}
              onChange={(e) => handleChange2(e)}
            />
            <p style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}>
              Enviar pagamentos por email
            </p>
          </div>

          <hr />
          <p
            style={{
              padding: "1rem",

              color: "#1B55EC",
              margin: 0,
              fontWeight: 500,
              fontSize: 20,
              maxWidth: "400px",
              lineHeight: 1,
            }}
          >
            Pagamentos Anuais
          </p>
          {pagamentoTipo.map((pagamento, index) => {
            var keyAux;
            var valueAux;
            for (const key in JSON.parse(pagamento)) {
              keyAux = key;
              valueAux = JSON.parse(pagamento)[key];
              mapValor.Valor[keyAux] = valueAux;
            }

            return (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center gap-2"
              >
                <div className="check_box_info d-flex align-items-center">
                  <Checkbox
                    icon={<img src={redIcon} alt="" />}
                    checkedIcon={<img src={greenIcon} alt="" />}
                    sx={{
                      color: "#C83953",
                      "&.Mui-checked": {
                        color: "#4DC839",
                      },
                    }}
                    onChange={(e) =>
                      handleChange(e, keyAux, valueAux.toString())
                    }
                  />
                  <p style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}>
                    {keyAux}
                  </p>
                </div>

                <div
                  className="check_box_border w-100 m-0 p-0"
                  style={{
                    borderBottomStyle: "dotted",
                    borderBottomWidth: "2px",
                    borderColor: "#EDEDED",
                  }}
                ></div>

                <div className="check_box_preco">
                  <p style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}>
                    {valueAux.toString() + "€"}
                  </p>
                </div>
              </div>
            );
          })}
          <hr />
          <p
            style={{
              padding: "1rem",

              color: "#1B55EC",
              margin: 0,
              fontWeight: 500,
              fontSize: 20,
              maxWidth: "400px",
              lineHeight: 1,
            }}
          >
            Pagamentos Mensais
          </p>
          {mensalidades.map((mensalidade, index) => {
            var keyAux;
            var valueAux;
            for (const key in JSON.parse(mensalidade)) {
              keyAux = key;
              valueAux = JSON.parse(mensalidade)[key];
              mapValorMensalidades.Valor_mensalidades[keyAux] = valueAux;
            }
            return (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center gap-2"
              >
                <div className="check_box_info d-flex align-items-center">
                  <Checkbox
                    icon={<img src={redIcon} alt="" />}
                    checkedIcon={<img src={greenIcon} alt="" />}
                    sx={{
                      color: "#C83953",
                      "&.Mui-checked": {
                        color: "#4DC839",
                      },
                    }}
                    onChange={(e) =>
                      handleChange(e, keyAux, valueAux.toString())
                    }
                  />
                  <p style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}>
                    {keyAux}
                  </p>
                </div>

                <div
                  className="check_box_border w-100 m-0 p-0"
                  style={{
                    borderBottomStyle: "dotted",
                    borderBottomWidth: "2px",
                    borderColor: "#EDEDED",
                  }}
                ></div>

                <div className="check_box_preco">
                  <p style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}>
                    {valueAux.toString() + "€"}
                  </p>
                </div>
              </div>
            );
          })}
        </DialogContent>

        <Button
          style={{ color: "white" }}
          className="btn-primary p-3 m-5"
          hidden={isHiden}
          autoFocus
          onClick={async () => {
            setIsHiden(true);
            await enviarPagamentosAtleta(
              clube,
              epoca,
              mapNomeValor,
              props.atleta,
              enviarEmail,
              props.atletaID,
              clubeID
            );
            setIsHiden(false);
          }}
        >
          Enviar
        </Button>
        <Button disabled={true} hidden={!isHiden}>
          <span
            class="btn-primary p-3 m-5 spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Loading
        </Button>
      </BootstrapDialog>
    </div>
  );
} */
