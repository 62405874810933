import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import db from "../../firebase.config";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { Container, Row, Col, Table } from "react-bootstrap";
import { setEncarregado } from "../../reducers/encarregado_reducer";
import CustomizedDialogs from "../../components/dialog/dialog_component";
import { selectClube } from "../../reducers/clube_reducer";
import Banner from "../../components/bannerPaginas/Banner";
import {
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { inscricoesCollection } from "../../consts/constants";
import { selectpagamentosTipo } from "../../reducers/pagamentos_tipo_reducer";
import { selectmensalidade } from "../../reducers/mensalidades_recuder";
import { JsonToExcel } from "react-json-to-excel";

var inscricoesConstVariavelGlob = [];

function InscricoesMainScreen() {
  const isEven = (num) => num % 2 === 0;
  const [inscricao, setListaInscricoes] = useState([]);

  const dispatch = useDispatch();
  const clube = useSelector(selectClube);

  async function fetchAtletas() {
    console.log("fetching From Firebase");
    try {
      const q = query(
        collection(db, inscricoesCollection),
        where("clube", "==", clube),
        where("estado", "==", "Pendente")
      );
      onSnapshot(q, (querySnapshot) => {
        var getInscricoes = [];
        inscricoesConstVariavelGlob = [];
        querySnapshot.forEach((doc) => {
          getInscricoes.push(doc);

          inscricoesConstVariavelGlob.push(doc);
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
            }
            if (change.type === "modified") {
            }
            if (change.type === "removed") {
            }
          });
        });
        getInscricoes.sort((a, b) =>
          a.nomeCompletoEncarregado > b.nomeCompletoEncarregado ? 1 : -1
        );
        console.log("inscricoesConstVariavelGlob");
        console.log(inscricoesConstVariavelGlob);
        setListaInscricoes(getInscricoes);
      });
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  }

  function sortByNome() {
    var listaAux = [].concat(inscricoesConstVariavelGlob);
    listaAux.sort((a, b) =>
      a.data().nomeCompletoEncarregado > b.data().nomeCompletoEncarregado
        ? 1
        : -1
    );

    setListaInscricoes(listaAux);
    console.log(inscricao);
  }
  function sortByDataDeNascimento() {
    var listaAux = [].concat(inscricoesConstVariavelGlob);
    listaAux.sort((a, b) =>
      a.data().dataDeNascimentoAtleta > b.data().dataDeNascimentoAtleta ? 1 : -1
    );
    setListaInscricoes(listaAux);
    console.log(inscricao);
  }

  function sortByNomeAtleta() {
    var listaAux = [].concat(inscricoesConstVariavelGlob);
    listaAux.sort((a, b) =>
      a.data().nomeCompletoAtleta > b.data().nomeCompletoAtleta ? 1 : -1
    );
    setListaInscricoes(listaAux);
    console.log(inscricao);
  }

  function pesquisaPorNomeAtleta() {
    var name = document.getElementById("pesquisarNome").value;
    var listaAux = [].concat(inscricoesConstVariavelGlob);
    var listaAux2 = [];
    listaAux.map((elem) => {
      if (elem.data().nomeCompletoAtleta.includes(name)) {
        listaAux2.push(elem);
      }
      return null;
    });
    console.log(listaAux2);
    setListaInscricoes(listaAux2);
  }

  function pesquisaPorEmailEncarregado() {
    var name = document.getElementById("pesquisarEmail").value;
    var listaAux = [].concat(inscricoesConstVariavelGlob);
    var listaAux2 = [];
    listaAux.map((elem) => {
      if (elem.data().emailEncarregado.includes(name)) {
        listaAux2.push(elem);
      }
      return null;
    });
    console.log(listaAux2);
    setListaInscricoes(listaAux2);
  }

  /* const [inscExcel, setInscExcel] = useState([]);
  var listaAUx = [];

  //recebe a lista de atletas, filtrada ou nao, e converte para excel
  function converteJsonToExcel(listaDeAltetas) {
    var atletasAux = [].concat(listaDeAltetas);
    var listaExcel = [];

      Nome atleta, data nascimento atleta, nome encarregado, 
      email, número telemóvel, numero camisola,  cc  

    atletasAux.sort((a, b) => {
      if (a.nomeCompletoAtleta > b.nomeCompletoAtleta) {
        return 1;
      } else {
        return -1;
      }
    });

    atletasAux.forEach((element) => {
      var mapAux = {
        "Nome do Atleta": element.nomeCompletoAtleta,
        "Data de Nascimento Atleta": element.dataDeNascimentoAtleta,
        "Cartão de cidadão Atleta": element.numeroCCAtleta,
        "Morada do Atleta": element.moradaAtleta,

        "Nome do Encarregado": element.nomeCompletoEncarregado,
        Email: element.emailEncarregado,
        "Número de telemóvel Encarregado": element.contactoTeleEncarregado,
        "Cartão de cidadão Encarregado": element.numeroCCEncarregado,
        "NIF do encarregado": element.nifEncarregado,
        "Morada do encarregado": element.moradaEncarregado,
      };

      listaExcel.push(mapAux);
    });

    setInscExcel(listaExcel);
    return listaExcel;
  } */

  /* Navigation */
  const navigate = useNavigate();
  const handleOnClick = useCallback(
    () => navigate("/inscricoes/inscricao", { replace: false }),
    [navigate]
  );
  /* NAvigation */
  useEffect(() => {
    fetchAtletas();
  }, []);

  const renderDocList = () => {
    console.log("render doc list called");
    return (
      <div className="container_inscricoes">
        <Banner />
        <div className="container_inscricoes_info">
          {/* Titulo */}
          <div className="heading_pagina">
            <h3>Inscrições Pendentes</h3>
            <p>
              Resultados: <span>{inscricao.length}</span>
            </p>
          </div>
          {/*  <div className="exportar_excel">
            <JsonToExcel
              title="Exportar Csv"
              data={inscExcel}
              fileName="sample-file"
              className="excel_btn"
            />
          </div> */}
          {/* Pesquisas */}

          <Container fluid>
            <Row>
              <Col>
                <Row>
                  <Col style={{ padding: 0 }}>
                    <div class="input-group gap-3">
                      <input
                        id="pesquisarNome"
                        type="text"
                        class=" form-control"
                        placeholder="Pesquisar por nome do atleta"
                      />
                      <div class="input-group-append col-4">
                        <button
                          class="btn d-flex align-items-center"
                          type="button"
                          onClick={() => pesquisaPorNomeAtleta()}
                        >
                          <span class="material-symbols-outlined">search</span>
                          &nbsp; Pesquisar
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col style={{ padding: 0 }}>
                    <div class="input-group gap-3">
                      <input
                        id="pesquisarEmail"
                        type="text"
                        class=" form-control"
                        placeholder="Pesquisar por email do encarregado"
                      />
                      <div class="input-group-append col-4">
                        <button
                          class="btn d-flex align-items-center"
                          type="button"
                          onClick={() => pesquisaPorEmailEncarregado()}
                        >
                          <span class="material-symbols-outlined">search</span>
                          &nbsp; Pesquisar
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Row className="pt-5 pb-2">
                <hr />
              </Row>

              <div className="container_tabela w-100 table-responsive">
                <Table className="table  table-hover ">
                  <TableHead>
                    <TableRow>
                      <th scope="col" onClick={() => sortByNome()}>
                        Nome do Encarregado
                      </th>
                      <th scope="col" onClick={() => sortByNomeAtleta()}>
                        Nome do atleta
                      </th>
                      <th scope="col" onClick={() => sortByDataDeNascimento()}>
                        Email do encarregado
                      </th>
                      <th scope="col">Opções:</th>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                    inscricao.length === 0 ?
                       <h4 style={{paddingTop: "40px", paddingLeft: "20px"}}>Sem inscrições pendentes</h4> :
                    inscricao.map((inscricaoAux, index) => {
                      console.log(inscricao);
                   
                      var inscricaoVa = inscricaoAux.data();
                      return (
                        <TableRow
                          key={index.toString() + "Row"}
                          className={index % 2 === 0 ? "blue" : "white"}
                        >
                          <TableCell>
                            <p key={index.toString() + "nomeEn"}>
                              {inscricaoVa.nomeCompletoEncarregado}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p key={index.toString() + "nomeAt"}>
                              {inscricaoVa.nomeCompletoAtleta}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p key={index.toString() + "email"}>
                              {inscricaoVa.emailEncarregado}
                            </p>
                          </TableCell>
                          <TableCell>
                            <CustomizedDialogs
                              inscPenden={inscricaoAux}
                            ></CustomizedDialogs>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  };

  return <>{renderDocList()}</>;
}

export default InscricoesMainScreen;

/* 
    /*  const querySnapshot = await getDocs(q); */
/* querySnapshot.forEach((doc) => {
      getAltetas.push({
        ...doc.data(),
        key: doc.id,
      });
      setAtletas(getAltetas);
    }); */
