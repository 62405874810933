import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import Banner from "../../components/bannerPaginas/Banner";
import ExampleDialog from "../../components/dialog/editar_dialog";
import { selectmensalidade } from "../../reducers/mensalidades_recuder";
import { selectpagamentosTipo } from "../../reducers/pagamentos_tipo_reducer";

function PagamentosScreen() {
  var pagamentoTipo = useSelector(selectpagamentosTipo);
  var mensalidades = useSelector(selectmensalidade);
  var mapValor = { Valor: {} };
  var mapValorMensalidades = { Valor_mensalidades: {} };
  console.log(pagamentoTipo);
  for (const key in pagamentoTipo) {
    console.log("Valores do Map: ", JSON.parse(pagamentoTipo[key]));
  }
  const renderPagamentosList = () => {
    console.log("pagamentos rendered");
    return (
      <div className="app_container_main_section">
        <Banner />
        <div
          className="app_container_main_section_content"
          style={{ paddingTop: "0px" }}
        >
          <div className="heading"></div>

          {/* Tabela */}
          <div className="tabela_generica w-100 table-responsive">
            <Table className="table  table-hover ">
              <Container className="p-5" fluid>
                <Row>
                  <Col className="col col-12">
                    <p className="textTitle" style={{ paddingBottom: "20px" }}>
                      Pagamentos Anuais
                    </p>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <h4 class="col-4" style={{ textAlign: "center" }}>
                    Nome
                  </h4>
                  <h4 class="col-4" style={{ textAlign: "center" }}>
                    Valor
                  </h4>

                  <p class="col-4"> </p>
                </Row>
                {pagamentoTipo.map((value, index) => {
                  var keyAux;
                  var valueAux;
                  for (const key in JSON.parse(value)) {
                    keyAux = key;
                    valueAux = JSON.parse(value)[key];
                    mapValor.Valor[keyAux] = valueAux;
                  }
                  return (
                    <div key={index}>
                      <Container key={index.toString() + "container"} fluid>
                        <div key={index}>
                          <Row
                            key={index.toString() + "Row"}
                            style={{
                              textAlign: "center",
                              whiteSpace: "pre-wrap",
                              overflowWrap: "break-word",
                            }}
                          >
                            <p key={index.toString() + "nome"} class="col-4">
                              {keyAux}
                            </p>
                            <p key={index.toString() + "dn"} class="col-4">
                              {valueAux + "€"}
                            </p>

                            <div class="col-4">
                              <ExampleDialog
                                mapValor={mapValor}
                                nome={keyAux}
                                valor={valueAux}
                              ></ExampleDialog>
                            </div>
                          </Row>
                        </div>
                      </Container>
                    </div>
                  );
                })}
                <Col className="col col-12">
                  <p className="textTitle" style={{ paddingBottom: "20px" }}>
                    Mensalidades
                  </p>
                </Col>
                {mensalidades.map((value2, index2) => {
                  var keyAux;
                  var valueAux;
                  for (const key in JSON.parse(value2)) {
                    keyAux = key;
                    valueAux = JSON.parse(value2)[key];
                    mapValorMensalidades.Valor_mensalidades[keyAux] = valueAux;
                  }
                  return (
                    <div key={index2}>
                      <Container key={index2.toString() + "container"} fluid>
                        <div key={index2}>
                          <Row
                            key={index2.toString() + "Row"}
                            style={{
                              textAlign: "center",
                              whiteSpace: "pre-wrap",
                              overflowWrap: "break-word",
                            }}
                          >
                            <p key={index2.toString() + "nome"} class="col-4">
                              {keyAux}
                            </p>
                            <p key={index2.toString() + "dn"} class="col-4">
                              {valueAux + "€"}
                            </p>

                            <div class="col-4">
                              <ExampleDialog
                                mapValor={mapValorMensalidades}
                                nome={keyAux}
                                valor={valueAux}
                              ></ExampleDialog>
                            </div>
                          </Row>
                        </div>
                      </Container>
                    </div>
                  );
                })}
              </Container>
            </Table>
          </div>
        </div>
      </div>
    );
  };

  return <>{renderPagamentosList()}</>;
}

export default PagamentosScreen;
