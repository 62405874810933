import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Expenses from "./screens/esxpenses";
import HomeScreen from "./screens/dashboard/home_screen";
import AtletasMainScreen from "./screens/atletas/atletas_main_scree";
import store from "./store";
import { Provider } from "react-redux";
import AltelaPerfil from "./screens/atletas/atleta_perfil_screen";
import AtletasEditarDados from "./screens/atletas/atletas_editar_dados";
import LoginScreen from "./screens/auth/login_screen";
import InscricoesMainScreen from "./screens/inscricoes_pendentes.js/inscricoes_pendentes_screen";
import PagamentosScreen from "./screens/pagamentos/pagamentos_main_screen";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import ClubeScreen from "./screens/clube/clube_Screen";
import AtletasConfirmados from "./screens/atletas/atletas_paga_confirmados";
import AteltasCancelados from "./screens/atletas/atletas_paga_cancelados";
import "./fonts/Urbanist/Urbanist-Regular.ttf";
import AtletasAtrasados from "./screens/atletas/atletas_pagamentos_atrasados";
import PagamentosEmFalta from "./screens/dashboard/pagamenots_em_falta";
import AtletasNaoInscritosScreen from "./screens/atletas/atletas_nao_inscritos";
import NotFound from "./screens/404";

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <HashRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<HomeScreen />} />
              <Route path="login" element={<LoginScreen />}></Route>
              <Route path="expenses" element={<Expenses />} />
              <Route path="inscricoes" element={<InscricoesMainScreen />} />
              <Route
                path="/inscricoes/inscricao"
                element={<InscricoesMainScreen />}
              />
              <Route path="dashboard" element={<HomeScreen />} />

              <Route
                path="dashboard/pagamentosEmFalta"
                element={<PagamentosEmFalta />}
              />
              <Route path="atletas" element={<AtletasMainScreen />} />
              <Route path="atletas/atleta" element={<AltelaPerfil />} />
              <Route
                path="atletas/atleta/editar"
                element={<AtletasEditarDados />}
              />
              <Route
                path="atletas/atleta/pagamentos_confirmados"
                element={<AtletasConfirmados />}
              />
              <Route
                path="atletas/atleta/pagamentos_cancelados"
                element={<AteltasCancelados />}
              />
              <Route
                path="atletas/atleta/pagamentos_atrasados"
                element={<AtletasAtrasados />}
              />
              <Route
                path="atletaNaoInscrito"
                element={<AtletasNaoInscritosScreen />}
              />
              <Route path="pagamentos" element={<PagamentosScreen />} />
              <Route path="clube" element={<ClubeScreen />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </HashRouter>
      </React.StrictMode>
    </PersistGate>
  </Provider>
);

reportWebVitals();
