import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  FieldValue,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  atletasCollection,
  camisolaNrDocument,
  clubesCollection,
  comprasCollection,
  comprasEfetuadasECanceladas,
  inscricoesCollection,
} from "../../consts/constants";
import db from "../../firebase.config";

export async function updateDadosDoAtleta(
  atletaId,
  json,
  clubeId,
  epoca,
  numeroARemover
) {
  try {
    console.log(" A Atualizar dados do atleta ");
    await setDoc(
      doc(db, atletasCollection, atletaId),
      {
        emailEncarregado: json.emailEncarregado,
        nifEncarregado: json.nifEncarregado,
        contactoTeleEncarregado: json.contactoTeleEncarregado,
        isIsento: json.isIsento,
        nomeCompletoEncarregado: json.nomeCompletoEncarregado,
        moradaEncarregado: json.moradaEncarregado,
        dataDeNascimentoEncarregado: json.dataDeNascimentoEncarregado,
        numeroCCEncarregado: json.numeroCCEncarregado,
        mensalidadeAtiva: json.mensalidadeAtiva,
        morada: json.morada,
        dataDeNascimento: json.dataDeNascimento,
        nomeCompleto: json.nomeCompleto,
        nomeNaCamisola: json.nomeNaCamisola,
        numeroCC: json.numeroCC,
        numeroDaCamisolaFinal: json.numeroDaCamisolaFinal,
      },
      { merge: true }
    );
    console.log(" A Atualizar dados do atleta no clube ");
    await setDoc(
      doc(db, clubesCollection, clubeId, epoca, "atletas"),
      {
        Inscritos: {
          [atletaId]: {
            dataDeNascimento: json.dataDeNascimento,
            emailDoEncarregado: json.emailEncarregado,
            nomeDoAtleta: json.nomeCompleto,
            nomeDoEncaregado: json.nomeCompletoEncarregado,
          },
        },
      },
      { merge: true }
    );
    console.log(" A Atualizar o numero da camisola do atleta ");
    if (json.numeroDaCamisolaFinal !== null) {
      console.log("vai adicionar");
      if (numeroARemover === null) {
        console.log("vai adicionar SEM REMOVER");

        await setDoc(
          doc(db, clubesCollection, clubeId, epoca, camisolaNrDocument),
          {
            [json.dataDeNascimento.substring(0, 4)]: {
              [json.numeroDaCamisolaFinal]: [json.nomeCompleto, atletaId],
            },
          },
          { merge: true }
        );
      } else {
        console.log("vai adicionar e remover");
        await setDoc(
          doc(db, clubesCollection, clubeId, epoca, camisolaNrDocument),
          {
            [json.dataDeNascimento.substring(0, 4)]: {
              [numeroARemover]: deleteField(),
              [json.numeroDaCamisolaFinal]: [json.nomeCompleto, atletaId],
            },
          },
          { merge: true }
        );
      }
    }

    return true;
  } catch (error) {
    console.log(error);
    return null;
  }
}
export async function fetchCamisolaDataPorAno(anoDeNascimento, clubeID, epoca) {
  try {
    const docRef = doc(
      db,
      clubesCollection,
      clubeID,
      epoca,
      camisolaNrDocument
    );
    const docSnap = await getDoc(docRef);
    return docSnap.data()[anoDeNascimento];
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function verNumerosDacamisolaDisponiveis(
  anoDeNascimento,
  clubeID,
  epoca
) {
  var listaDeNumerosOcupados = [];
  try {
    fetchCamisolaDataPorAno(anoDeNascimento, clubeID, epoca).then((value) => {
      for (var i in value) {
        listaDeNumerosOcupados.push(i);
      }
    });
    return listaDeNumerosOcupados;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function updateNumeroDoAtleta(atletaId, anoDeNascimento, numero) {}

export async function escolherQualDosNumerosEstaDisponivel() {}

//remover a conta do Atleta
//remove os numeros do clube
//remover pagamentos pendentes
//remover inscricao pendente
export async function deleteAtletasAccount(atletaID, clubeId, epoca) {
  try {
    var altetaRef = doc(db, atletasCollection, atletaID);
    await setDoc(
      altetaRef,
      { mensalidadeAtiva: false, isInativo: true },
      { merge: true }
    );

    var docRef = doc(db, clubesCollection, clubeId, epoca, "atletas");
    await setDoc(
      docRef,
      { Inscritos: { [atletaID]: deleteField() } },
      { merge: true }
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

//retorna a inscricao pendente do atleta
export async function deleteComprasPendentes(atletaID, clubeId, epoca) {
  try {
    const q = query(
      collection(db, comprasCollection),
      where("atletaId", "==", atletaID)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (docData) => {
      await setDoc(
        doc(db, comprasCollection, docData.id),
        { estado: "Anulada" },
        { merge: true }
      );
      var cepNum = comprasEfetuadasECanceladas + docData.data().cep;
      await setDoc(
        doc(db, clubesCollection, clubeId, epoca, cepNum),
        {
          Pendetes: { [docData.id]: deleteField() },
        },
        { merge: true }
      );
    });
    return true;
  } catch (error) {
    console.log(error);
    return null;
  }
}
