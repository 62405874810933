import { Container } from "@mui/system";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { clubesCollection } from "../../consts/constants";
import db from "../../firebase.config";
import { selectclubeId } from "../../reducers/clube_id_reducer";
import { selectEpoca } from "../../reducers/epoca_reducer";

function ExampleDialoga(props) {
  const [show, setShow] = useState(false);
  const [valorDoPag, setvalorDoPag] = useState(props.valor);

  var clubeId = useSelector(selectclubeId);
  var epoca = useSelector(selectEpoca);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function updateValor(valueP) {
    try {
      var mapAux = props.mapValor;

      if (typeof props.mapValor.Valor == "undefined") {
        mapAux.Valor_mensalidades[props.nome] = parseFloat(valueP);
        const cityRef = doc(db, clubesCollection, clubeId, epoca, "pagamentos");
        updateDoc(cityRef, mapAux);
      } else {
        mapAux.Valor[props.nome] = parseFloat(valueP);
        const cityRef = doc(db, clubesCollection, clubeId, epoca, "pagamentos");
        updateDoc(cityRef, mapAux);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      <div>
        <Button onClick={handleShow}>Editar Valor</Button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{props.nome}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">Valor</span>
              </div>
              <input
                onChange={(e) => setvalorDoPag(e.target.value)}
                value={valorDoPag}
                type="text"
                class="form-control"
                aria-label="Amount (to the nearest dollar)"
              ></input>
              <div class="input-group-append">
                <span class="input-group-text">€</span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                updateValor(valorDoPag).then((value) => {
                  handleClose();
                })
              }
            >
              Alterar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
}

export default ExampleDialoga;
