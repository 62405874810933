import DashBoardCard from "../../components/dashboard/dashboard_card";
import Banner from "../../components/bannerPaginas/Banner";
import { selectvaloresAPagar } from "../../reducers/valores_a_pagar_reducer";
import { selectvaloresPagos } from "../../reducers/valores_pagos_reducer";
import { useSelector } from "react-redux";
import { selectCompras } from "../../reducers/compras_efetuadas_reduce";
import { selectcomprasPendentes } from "../../reducers/compras_pendentes_reducer";
import { Button } from "@mui/material";
import {
  selectEncarregadoId,
  setEncarregadoId,
} from "../../reducers/encarregado_id_reducer";
import store from "../../store";
import { selectcep } from "../../reducers/cep_reducer";
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import db from "../../firebase.config";
import {
  atletasCollection,
  clubesCollection,
  comprasCollection,
  comprasEfetuadasECanceladas,
  inscricoesCollection,
} from "../../consts/constants";
import { selectclubeId } from "../../reducers/clube_id_reducer";
import { selectEpoca } from "../../reducers/epoca_reducer";
import { selectnumerosCamisola } from "../../reducers/numeros_camisola_reducer";
import { async } from "@firebase/util";
import Atleta from "../../models/atleta";
import InscricaoPendente from "../../models/inscricoes";
import Compra from "../../models/compra";
import CompraV2 from "../../models/compraV2";
import {
  adicionaCompra,
  adicionaCompraAnulada,
  adicionaCompraAoClube2,
  adicionaCompraAoClube2Anulada,
  adicionaCompraAoClube2Concluida,
  adicionaCompraConcluida,
  atualizarNumeroDoCEPNaCompra,
} from "../../services/compras_services";
import { addPagamentosEmAtraso } from "../../services/encarregados_services";

function HomeScreen() {
  var valoresAPagar = useSelector(selectvaloresAPagar);
  var valoresPagos = useSelector(selectvaloresPagos);
  var clubeId = useSelector(selectclubeId);
  var epoca = useSelector(selectEpoca);
  var cep = useSelector(selectcep);
  var encarregadoId = useSelector(selectnumerosCamisola);

  var lista1 = useSelector(selectCompras);
  var lista2 = useSelector(selectcomprasPendentes);

  var listaAux1 = [];
  var listaAux2 = [];

  lista1.forEach((elem) => {
    listaAux1.push(JSON.parse(elem));
  });

  lista2.forEach((elem) => {
    listaAux2.push(JSON.parse(elem));
  });

  function transformaMapEmListaDePAgamentos(map) {
    var listaAux = [];
    for (var key in map) {
      listaAux.push(key);
    }
    return listaAux;
  }

  const renderScreen = () => {
    return (
      <>
        <section className="container_homepage">
          <Banner />
          <div className="grid_cards_hero teste">
            {transformaMapEmListaDePAgamentos(valoresAPagar).map(
              (pagamento, index) => {
                return (
                  <div key={index.toString() + "c1"}>
                    <DashBoardCard
                      nome={pagamento}
                      valorAPagar={valoresAPagar[pagamento]}
                      valorPago={valoresPagos[pagamento]}
                    />
                  </div>
                );
              }
            )}
          </div>
        </section>
      </>
    );
  };

  return <>{renderScreen()}</>;
}

export default HomeScreen;
