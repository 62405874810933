import React from "react";
import { updateAllEncarregados } from "../services/clube_services";
import { getFirestore } from "firebase/firestore";

export default function Expenses() {
  return (
    <main style={{ padding: "1rem 0" }}>
      <h2>Expenses</h2>
    </main>
  );
}
