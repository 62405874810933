import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import db from "../../firebase.config";
import {
  onSnapshot,
  doc,
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { Container, Row, Col, Table } from "react-bootstrap";
import { JsonToExcel } from "react-json-to-excel";
import { selectClube } from "../../reducers/clube_reducer";
import Banner from "../../components/bannerPaginas/Banner";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { capitalizeFirstLetter } from "../../services/functions_aux";
import { clubesCollection } from "../../consts/constants";
import { selectEpoca } from "../../reducers/epoca_reducer";
import { selectclubeId } from "../../reducers/clube_id_reducer";
import { setAtletaId } from "../../reducers/atleta_id_reducer";

var atletasConstVariavelGlob = [];

function AtletasMainScreen() {
  const isEven = (num) => num % 2 === 0;
  const [paraRemov, setparaRemov] = useState([]);
  const [atletas, setListaAtletas] = useState([]);
  const [atletasExcel, setListaAtletasExcel] = useState([]);
  const dispatch = useDispatch();
  const clube = useSelector(selectClube);

  const [nomeOrd, setnomeOrd] = useState([false]);
  const [dataDeNAsOrd, setdataDeNAsOrd] = useState([false]);
  const [pagsEmAtraOrd, setpagsEmAtraOrd] = useState([false]);

  var epoca = useSelector(selectEpoca);
  var clubeId = useSelector(selectclubeId);
  /*   const [listaDeAtletas, setListaDeAtletas] = useState({}); */
  const [listaAtletas, setlistAtletas] = useState([]);

  async function fetchAtletasInscrtos() {
    var docRef = doc(db, clubesCollection, clubeId, epoca, "atletas");
    const unsub = onSnapshot(docRef, (doc) => {
      var listaAuxId2 = [];
      atletasConstVariavelGlob = [];
      /*   setListaDeAtletas(doc.data().Inscritos); */
      for (const key in doc.data().Inscritos) {
        listaAuxId2.push({ [key]: doc.data().Inscritos[key] });
        atletasConstVariavelGlob.push({ [key]: doc.data().Inscritos[key] });
      }
      setlistAtletas(listaAuxId2);
    });
  }

  function sortByNome() {
    listaAtletas.sort((a, b) => {
      var aux;
      var aux2;
      for (var key in a) {
        aux = a[key];
      }
      for (var key2 in b) {
        aux2 = b[key2];
      }
      if (nomeOrd) {
        return aux.nomeDoAtleta > aux2.nomeDoAtleta ? 1 : -1;
      } else {
        return aux.nomeDoAtleta < aux2.nomeDoAtleta ? 1 : -1;
      }
    });
    setnomeOrd(!nomeOrd);
  }

  function sortByDataDeNascimento() {
    listaAtletas.sort((a, b) => {
      var aux;
      var aux2;
      for (var key in a) {
        aux = a[key];
      }
      for (var key2 in b) {
        aux2 = b[key2];
      }
      if (dataDeNAsOrd) {
        return aux.dataDeNascimento > aux2.dataDeNascimento ? 1 : -1;
      } else {
        return aux.dataDeNascimento < aux2.dataDeNascimento ? 1 : -1;
      }
    });
    setdataDeNAsOrd(!dataDeNAsOrd);
  }

  function sortByPagamentosEmAtraso() {
    listaAtletas.sort((a, b) => {
      var aux;
      var aux2;
      for (var key in a) {
        aux = a[key];
      }
      for (var key2 in b) {
        aux2 = b[key2];
      }
      if (pagsEmAtraOrd) {
        return aux.PagamentosEmAtraso > aux2.PagamentosEmAtraso ? 1 : -1;
      } else {
        return aux.PagamentosEmAtraso < aux2.PagamentosEmAtraso ? 1 : -1;
      }
    });
    setpagsEmAtraOrd(!pagsEmAtraOrd);
  }

  function filtrarPorDataDeNascimento(ano) {
    if (ano === "") {
      setlistAtletas(atletasConstVariavelGlob);
    } else {
      var listaAux = [].concat(atletasConstVariavelGlob);
      var listaAUxAux = [];
      listaAux.forEach((ele) => {
        var aux;
        for (var key in ele) {
          aux = ele[key];
        }
        return aux.dataDeNascimento.substring(0, 4) === ano
          ? listaAUxAux.push(ele)
          : null;
      });

      setlistAtletas(
        listaAUxAux
      ); /*  setListaAtletasExcel(converteJsonToExcel(listaAUxAux)); */
      console.log(atletas);
    }
  }

  function pesquisaPorNome() {
    var name = document.getElementById("pesquisarNome").value;
    if (name === "") {
      setlistAtletas(atletasConstVariavelGlob);
    } else {
      var listaAux = [].concat(atletasConstVariavelGlob);
      var listaAUxAux = [];
      listaAux.forEach((ele) => {
        var aux;
        for (var key in ele) {
          aux = ele[key];
        }
        if (aux.nomeDoAtleta.includes(name)) {
          return listaAUxAux.push(ele);
        } else if (aux.nomeDoAtleta.includes(capitalizeFirstLetter(name))) {
          return listaAUxAux.push(ele);
        }
        return null;
      });
      setlistAtletas(listaAUxAux);
    }
  }

  function pesquisaPorEmail() {
    var email = document.getElementById("pesquisarEmail").value;
    if (email === "") {
      setlistAtletas(atletasConstVariavelGlob);
    } else {
      var listaAux = [].concat(atletasConstVariavelGlob);
      var listaAUxAux = [];
      listaAux.forEach((ele) => {
        var aux;
        for (var key in ele) {
          aux = ele[key];
        }
        if (aux.emailDoEncarregado.includes(email)) {
          return listaAUxAux.push(ele);
        }
        return null;
      });

      setlistAtletas(listaAUxAux);
    }

    /*  setListaAtletasExcel(converteJsonToExcel(listaAux2)); */
  }

  const [novasCompras, setNovasCompras] = useState([]);
  const date2 = new Date("2022-09-30T03:24:00");

  async function getComprasEnviadasPorEmail() {
    console.log("getComprasEnviadasPorEmail");
    var listaAux = [];
    var atletasDocSnaps = query(
      collection(db, "compras"),
      where("createdAt", ">", Timestamp.fromDate(date2))
    );
    await getDocs(atletasDocSnaps).then((value) => {
      value.forEach((elem) => {
        listaAux.push(elem.data());
      });
      console.log("listaAux");
      console.log(listaAux);
      setNovasCompras(listaAux);
      console.log(novasCompras);
    });
  }
  //recebe a lista de atletas, filtrada ou nao, e converte para excel
  function converteJsonToExcel(listaDeAltetas) {
    var atletasAux = [].concat(listaDeAltetas);
    var listaExcel = [];

    /*Nome atleta, data nascimento atleta, nome encarregado, 
      email, número telemóvel, numero camisola,  cc  */

    atletasAux.forEach((element) => {
      var mapAux = {
        "Nome do Atleta": element.atletaNome,
        "Nome do Encarregado": element.encarregadoNome,
        "Email do Encarregado": element.encarregadoEmail,
        "Request Id": element.requestId,
      };
      listaExcel.push(mapAux);
    });
    listaExcel.sort((a, b) => {
      if (a.Data_do_registo > b.Data_do_registo) {
        return 1;
      } else {
        return -1;
      }
    });
    return listaExcel;
  }
  //ordena por data de criaççao o excel

  /* Navigation */
  const navigate = useNavigate();
  const handleOnClick = useCallback(
    () => navigate("/atletas/atleta", { replace: false }),
    [navigate]
  );

  /* NAvigation */
  /*  console.log(listaDeAtletas); */
  console.log(listaAtletas);

  useEffect(() => {
    fetchAtletasInscrtos();
    getComprasEnviadasPorEmail();
  }, []);

  const renderDocList = () => {
    console.log("render doc list called");
    return (
      <div className="container_atletas">
        <Banner />

        <div className="container_atletas_info">
          {/* Titulo */}
          <div className="heading_pagina">
            <div className="titulo_pagina">
              <h3>Atletas</h3>
              <p>
                Resultados: <span>{listaAtletas.length}</span>
              </p>
            </div>

            <div className="exportar_excel">
              <JsonToExcel
                title="Exportar Csv"
                data={converteJsonToExcel(novasCompras)}
                fileName="sample-file"
                className="excel_btn"
              />
            </div>
          </div>

          <Container fluid>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div class="input-group gap-3">
                      <input
                        id="filtrarPorData"
                        type="text"
                        maxlength="4"
                        name="country_code"
                        title="Error Message"
                        pattern="[1-9]{1}[0-9]{9}"
                        class=" form-control"
                        placeholder="Filtrar por data de nascimento"
                      ></input>
                      <div class="input-group-append col-4">
                        <button
                          class="btn d-flex align-items-center"
                          type="button"
                          onClick={() =>
                            filtrarPorDataDeNascimento(
                              document.getElementById("filtrarPorData").value
                            )
                          }
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                          &nbsp;
                          <span className="">Filtrar</span>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col>
                    <div class="input-group gap-3">
                      <input
                        id="pesquisarNome"
                        type="text"
                        class=" form-control"
                        placeholder="Pesquisar por nome"
                      />
                      <div class="input-group-append col-4">
                        <button
                          class="btn d-flex align-items-center "
                          type="button"
                          onClick={() => pesquisaPorNome()}
                        >
                          <span class="material-symbols-outlined">search</span>
                          &nbsp;
                          {/* "d-none d-sm-block */}
                          <span className="">Pesquisar</span>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col>
                    <div class="input-group gap-3">
                      <input
                        id="pesquisarEmail"
                        type="text"
                        class=" form-control"
                        placeholder="Pesquisar por email do encarregado"
                      ></input>
                      <div class="input-group-append col-4">
                        <button
                          class="btn d-flex align-items-center "
                          type="button"
                          onClick={() => pesquisaPorEmail()}
                        >
                          <span class="material-symbols-outlined">search</span>
                          &nbsp;
                          <span className="">Pesquisar</span>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              {/* Tabela */}
              <div className=" container_tabela table-responsive">
                <Table className="table table-hover ">
                  <TableHead>
                    <TableRow>
                      <th scope="col" onClick={() => sortByNome()}>
                        {" "}
                        Nome{" "}
                      </th>
                      <th scope="col" onClick={() => sortByDataDeNascimento()}>
                        {" "}
                        Ano de Nascimento
                      </th>
                      <th
                        scope="col"
                        onClick={() => sortByPagamentosEmAtraso()}
                      >
                        {" "}
                        Pagamentos em Atraso
                      </th>
                      <th scope="col">Opções:</th>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {listaAtletas === [] || listaAtletas.length === 0 ? (
                      <p>Sem alunos inscritos</p>
                    ) : (
                      listaAtletas.map((atleta, index) => {
                        var jsonData;
                        var atletaId;
                        for (const key in atleta) {
                          jsonData = atleta[key];
                          atletaId = key;
                        }
                        return (
                          <TableRow
                            key={index.toString() + "Row"}
                            className={index % 2 === 0 ? "blue" : "white"}
                          >
                            <TableCell key={index.toString() + "nome"}>
                              {jsonData.nomeDoAtleta}
                            </TableCell>
                            <TableCell>
                              <p key={index.toString() + "dn"}>
                                {jsonData.dataDeNascimento.substring(0, 4)}
                              </p>
                            </TableCell>
                            <TableCell key={index.toString() + "atraso"}>
                              {jsonData.PagamentosEmAtraso ? (
                                <p key={index.toString() + "atraso"}>Sim</p>
                              ) : (
                                <p key={index.toString() + "atraso"}>Não</p>
                              )}
                            </TableCell>
                            <TableCell key={index.toString() + "butn"}>
                              <button
                                className="btn btn-primary buttonCenter"
                                type="button"
                                onClick={() => {
                                  handleOnClick();
                                  dispatch(setAtletaId(atletaId));
                                  console.log(atletaId);
                                }}
                              >
                                Informações
                              </button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  };

  return <>{renderDocList()}</>;
}

export default AtletasMainScreen;

/* 
    /*  const querySnapshot = await getDocs(q); */
/* querySnapshot.forEach((doc) => {
      getAltetas.push({
        ...doc.data(),
        key: doc.id,
      });
      setAtletas(getAltetas);
    }); */
