import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import multibanco from "../../assets/multibanco.png";
import { selectcep } from "../../reducers/cep_reducer";
import { selectclubeId } from "../../reducers/clube_id_reducer";
import { selectClube } from "../../reducers/clube_reducer";
import { selectEpoca } from "../../reducers/epoca_reducer";
import {
  anularPagamento,
  pagarComNumerario,
} from "../../services/compras_services";
import {
  criaEmailBOdy,
  enviarEmailPagamentos,
  htmlBodyMail,
} from "../../services/mail_services";
import { criaReferenciaEAtualizaDadaosDaCompra } from "../../services/multivanco_services";

function ReferenciaDialog(props) {
  const [show, setShow] = useState(false);
  const [isHiden, setIsHiden] = useState(false);
  var clube = useSelector(selectClube);
  var clubeId = useSelector(selectclubeId);
  var epoca = useSelector(selectEpoca);
  var cep = useSelector(selectcep);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function enviarEmailDaRef(
    tipoDaCompra,
    valorDaCompra,
    entidade,
    reference,
    encarregadoEmail,
    expiraEm
  ) {
    var htmlBody = htmlBodyMail;

    var emailData = criaEmailBOdy(
      tipoDaCompra,
      valorDaCompra.toString(),
      entidade,
      reference,
      expiraEm
    );
    htmlBody = htmlBody + emailData;

    await enviarEmailPagamentos(
      encarregadoEmail,
      "Pagamentos ao " + clube,
      htmlBody
    );
  }

  function veSeExpirou(expiryAt) {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    month = ("0" + month).slice(-2);
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    var newdate = day + "-" + month + "-" + year;

    if (expiryAt.substring(7, 11) <= newdate.substring(6, 10)) {
      if (expiryAt.substring(4, 6) <= newdate.substring(3, 5)) {
        if (expiryAt.substring(1, 3) < newdate.substring(0, 2)) {
          return 1;
        }
      }
    }

    return null;
  }

  return (
    <Container fluid>
      <Button onClick={handleShow}>Ver referência</Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Referência de Multibanco</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex align-items-center p-2">
            <Col xs={4} md={3}>
              <img
                style={{ width: "100px" }}
                src={multibanco}
                alt="multibanco"
              ></img>
            </Col>
            <Col xs={8} md={9}>
              <p style={{ fontWeight: "bold" }}>
                Referência:
                <span style={{ fontWeight: "normal" }}>{props.referencia}</span>
              </p>

              <p style={{ fontWeight: "bold" }}>
                Entidade:
                <span style={{ fontWeight: "normal" }}>{props.entidade}</span>
              </p>
              <p style={{ fontWeight: "bold" }}>
                Valor:
                <span style={{ fontWeight: "normal" }}>{props.valor}</span>
              </p>
              <p style={{ fontWeight: "bold" }}>
                Expira em:
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  {veSeExpirou(props.expira) === 1 ? "Expirou" : props.expira}
                </span>
              </p>
              <p style={{ fontWeight: "bold" }}>
                Estado:
                <span style={{ fontWeight: "normal" }}>{props.estado}</span>
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-md-center">
          <Button disabled hidden={!isHiden}>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading
          </Button>

          <Col md={3}>
            <Button
              hidden={isHiden}
              onClick={() => {
                setIsHiden(true);
                anularPagamento(props.compraID, clubeId, epoca, cep).then(
                  (va) => {
                    handleClose();
                    setIsHiden(false);
                  }
                );
              }}
            >
              Anular Pagamento
            </Button>
          </Col>
          <Col md={3}>
            <Button
              hidden={isHiden}
              onClick={() => {
                setIsHiden(true);
                pagarComNumerario(props.compraID, clubeId, epoca, cep).then(
                  (value) => {
                    handleClose();
                    setIsHiden(false);
                  }
                );
              }}
            >
              Pagar com numerário
            </Button>
          </Col>
          <Col md={3}>
            <Button
              hidden={isHiden}
              onClick={() => {
                setIsHiden(true);
                criaReferenciaEAtualizaDadaosDaCompra(
                  props.atletaID,
                  props.valorRef,
                  props.compraID,
                  epoca,
                  clubeId,
                  props.docNum
                ).then((value) => {
                  handleClose();
                  setIsHiden(false);
                });
              }}
            >
              Gerar Nova Referência
            </Button>
          </Col>

          <Button
            hidden={isHiden}
            disabled={veSeExpirou(props.expira) === 1 ? true : false}
            style={{ marginTop: "40px" }}
            onClick={() => {
              setIsHiden(true);
              enviarEmailDaRef(
                props.tipoDaCompra,
                props.valorRef,
                props.entidade,
                props.referencia,
                props.encarregadoEmail,
                props.expira
              ).then((value) => {
                handleClose();
                setIsHiden(false);
              });
            }}
          >
            Enviar referência por email
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ReferenciaDialog;
