import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectAtletaPerfil } from "../../reducers/atleta_perfil_reducer";
import { Checkbox, Input } from "@mui/material";
import { getEncarregadoData } from "../../services/encarregados_services";
import { doc, setDoc } from "firebase/firestore";
import db from "../../firebase.config";
import NumerosDialog from "../../components/dialog/dialog_numeros_camisola";
import { selectAtletaId } from "../../reducers/atleta_id_reducer";
import {
  fetchCamisolaDataPorAno,
  updateDadosDoAtleta,
  verNumerosDacamisolaDisponiveis,
} from "../../services/atletaServices/atleta_editar_dados_services";
import { selectClube } from "../../reducers/clube_reducer";
import { selectEpoca } from "../../reducers/epoca_reducer";
import { selectclubeId } from "../../reducers/clube_id_reducer";
import Banner from "../../components/bannerPaginas/Banner";
import greenIcon from "../../assets/icons/greenIconSVG.svg";
import redIcon from "../../assets/icons/redIconSVG.svg";
import { mainColor } from "../../consts/constants";
import { useNavigate } from "react-router-dom";
import { selectnumerosCamisola } from "../../reducers/numeros_camisola_reducer";
import { addExameMedico } from "../../services/atletaServices/atleta_storage_services";

function AtletasEditarDados() {
  const atletaString = useSelector(selectAtletaPerfil);
  const atletaJSON = JSON.parse(atletaString);
  const atletaId = useSelector(selectAtletaId);
  const clubeId = useSelector(selectclubeId);
  const epoca = useSelector(selectEpoca);

  var camisolaNRData = useSelector(selectnumerosCamisola)
  var camisolaNRJson = JSON.parse(camisolaNRData);

  const [selectedImageFrente, setSelectedImageFrente] = useState(null);
  const [selectedImageTras, setSelectedImageTras] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isMensalidadeEmail, setMensalidadeEmail] = useState(
    atletaJSON.mensalidadeAtiva
  );
  const [isIsento, setIsIsento] = useState(atletaJSON.isIsento);
  const [emailEncarregado, setemailEncarregado] = useState(atletaJSON.emailEncarregado);
  const [nomeCompleto, setNomeCompleto] = useState(atletaJSON.nomeCompleto);
  const [nomeCompletoEncarregado, setNomeCompletoEncarregado] = useState(atletaJSON.nomeCompletoEncarregado);
  const [morada, setMorada] = useState(atletaJSON.morada);
  const [moradaEncarregado, setMoradaEncarregado] = useState(atletaJSON.moradaEncarregado);
  const [dataDeNascimento, setDataDeNascimento] = useState(atletaJSON.dataDeNascimento);
  const [dataDeNascimentoEncarregado, setDataDeNascimentoEncarregado] =
    useState(atletaJSON.dataDeNascimentoEncarregado);
  const [nomeNaCamisola, setNomeNaCamisola] = useState(atletaJSON.nomeNaCamisola);
  const [numeroCC, setNumeroCC] = useState(atletaJSON.numeroCC);
  const [numeroCCEncarregado, setNumeroCCEncarregado] = useState(atletaJSON.numeroCCEncarregado);
  const [numeroDaCamisolaFinal, setNumeroDaCamisolaFinal] = useState(atletaJSON.numeroDaCamisolaFinal);
  const [contactoTeleEncarregado, setContactoTeleEncarregado] = useState(atletaJSON.contactoTeleEncarregado);
  const [nifEncarregado, setnifEncarregado] = useState(atletaJSON.nifEncarregado);
  const [exameMedico, setExameMedico] = useState("");

  function handleChange(event) {
    setExameMedico(event.target.files[0]);
    addExameMedico(atletaJSON.clube, atletaJSON.emailEncarregado, atletaJSON.nomeCompleto,event.target.files[0],atletaId);
}


  var jsonData = {
    emailEncarregado: atletaJSON.emailEncarregado,
    nifEncarregado: atletaJSON.nifEncarregado,
    contactoTeleEncarregado: atletaJSON.contactoTeleEncarregado,
    isIsento: atletaJSON.isIsento,
    mensalidadeAtiva: atletaJSON.mensalidadeAtiva,
    morada: atletaJSON.morada,
    moradaEncarregado: atletaJSON.moradaEncarregado,
    dataDeNascimento: atletaJSON.dataDeNascimento,
    dataDeNascimentoEncarregado: atletaJSON.dataDeNascimentoEncarregado,
    nomeCompleto: atletaJSON.nomeCompleto,
    nomeCompletoEncarregado: atletaJSON.nomeCompletoEncarregado,
    nomeNaCamisola: atletaJSON.nomeNaCamisola,
    numeroCC: atletaJSON.numeroCC,
    numeroCCEncarregado: atletaJSON.numeroCCEncarregado,
    numeroDaCamisolaFinal: atletaJSON.numeroDaCamisolaFinal,
  };

  function atualizarJsonData() {
    jsonData.emailEncarregado = emailEncarregado;
    jsonData.nifEncarregado = nifEncarregado;
    jsonData.contactoTeleEncarregado = contactoTeleEncarregado;
    jsonData.isIsento = isIsento;
    jsonData.nomeCompleto = nomeCompleto;
    jsonData.nomeCompletoEncarregado = nomeCompletoEncarregado;
    jsonData.morada = morada;
    jsonData.moradaEncarregado = moradaEncarregado;
    jsonData.mensalidadeAtiva = isMensalidadeEmail;
    jsonData.dataDeNascimento = dataDeNascimento;
    jsonData.dataDeNascimentoEncarregado = dataDeNascimentoEncarregado;
    jsonData.nomeNaCamisola = nomeNaCamisola;
    jsonData.numeroCC = numeroCC;
    jsonData.numeroCCEncarregado = numeroCCEncarregado;
    jsonData.numeroDaCamisolaFinal = numeroDaCamisolaFinal;
  }

  var navigate = useNavigate();

  //ve se o id existe na lista. se existe substitui o numero
  var listaAux = []; 

  function veSeOAtletaJaTemNumero() {
    var numeroExiste = null;
    if(camisolaNRJson[dataDeNascimento.substring(0,4)] !== "undefined"  ) {
      for (var key in camisolaNRJson[dataDeNascimento.substring(0,4)]) {
        if(atletaId === camisolaNRJson[dataDeNascimento.substring(0,4)][key][1]) {
          numeroExiste=key;
        }
      
      }
    }
    return numeroExiste;
  }

  async function gravarAlteracs() {
    try {
      var numeroARemover = veSeOAtletaJaTemNumero();
      atualizarJsonData();
      await updateDadosDoAtleta(atletaId, jsonData, clubeId, epoca,numeroARemover);
      alert("Alterações gravadas com sucesso");
      navigate(-1);
      return true;
    } catch (error) {
      console.log(error);
      alert("Erro a gravar alterações");
      navigate(-1);
      return false;
    }
  }

  function handleChangeEmail(e) {
    const isChecked = e.target.checked;
    setMensalidadeEmail(isChecked);
    console.log(isChecked);
  }

  function handleChangeIsento(e) {
    const isChecked = e.target.checked;
    setIsIsento(isChecked);
    console.log(isChecked);
  }
  return (
    <div className="app_container_main_section">
      <Banner />
      <div class="container_atleta_perfil">
        <div className="heading">
          <h3>Editar Dados do Atleta</h3>
        </div>
        <Row className="mt-2">
          <Col>
            <div className="check_box_info d-flex align-items-center">
              <Checkbox
                icon={<img src={redIcon} alt="" />}
                checkedIcon={<img src={greenIcon} alt="" />}
                sx={{
                  color: "#C83953",
                  "&.Mui-checked": {
                    color: "#4DC839",
                  },
                }}
                checked={isMensalidadeEmail}
                onChange={(e) => handleChangeEmail(e)}
              />
              <p style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}>
                Mensalidade ativa
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <div className="check_box_info d-flex align-items-center">
              <Checkbox
                icon={<img src={redIcon} alt="" />}
                checkedIcon={<img src={greenIcon} alt="" />}
                sx={{
                  color: "#C83953",
                  "&.Mui-checked": {
                    color: "#4DC839",
                  },
                }}
                checked={isIsento}
                onChange={(e) => handleChangeIsento(e)}
              />
              <p style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}>
                Isento
              </p>
            </div>
          </Col>
        </Row>

        <h3 style={{ color: mainColor, paddingTop: "50px" }}>Atleta</h3>
        <Container fluid>
          <Row>
            <div className="p-0 container_titulos_valor d-flex gap-md-3 col-12 ">
              <div className="col-xs-12 col-md-3">
                <div className="titulo_valor">
                  <label for="inputNameAtleta">Nome Completo</label>
                  <input
                    type="text"
                    class="form-control"
                    value={nomeCompleto}
                    onChange={(e) => setNomeCompleto(e.target.value)}
                    id="inputNameAtleta"
                    placeholder="Nome"
                    required
                  />
                </div>
              </div>

              <div className="col-xs-12 col-md-3">
                <div className="titulo_valor">
                  <label for="inputBirthdayAtleta">Data de Nascimento</label>
                  <input
                    class="form-control"
                    type="date"
                    onChange={(e) => setDataDeNascimento(e.target.value)}
                    value={dataDeNascimento}
                    id="inputBirthdayAtleta"
                    name="birthday"
                    required
                  />
                </div>
              </div>

              <div className="col-xs-12 col-md-3">
                <div className="titulo_valor">
                  <label for="inputCartaoDeCidadao">Número do CC</label>
                  <input
                    type="number"
                    onChange={(e) => setNumeroCC(e.target.value)}
                    value={numeroCC}
                    class="form-control"
                    id="inputCartaoDeCidadao"
                    placeholder="Número do Cartão de Cidadão"
                    required
                  />
                </div>
              </div>
            </div>
          </Row>

          <Row>
            <div className="p-0 container_titulos_valor d-flex gap-md-3 col-12">
              <div className="col-xs-12 col-md-5">
                <div className="titulo_valor">
                  <label for="inputAddress">Morada</label>
                  <input
                    type="text"
                    onChange={(e) => setMorada(e.target.value)}
                    value={morada}
                    class="form-control"
                    id="inputAddress"
                    placeholder="Rua principal 1234"
                    required
                  />
                </div>
              </div>

              <div className="col-xs-12 col-md-4">
                <div className="titulo_valor">
                  <label for="nomeNaCamisola">Nome na camisola</label>
                  <input
                    onChange={(e) => setNomeNaCamisola(e.target.value)}
                    value={nomeNaCamisola}
                    type="text"
                    class="form-control"
                    id="nomeNaCamisola"
                    placeholder="Nome"
                    required
                  />
                </div>
              </div>
            </div>
          </Row>

          <Row>
            <div className="p-0 container_titulos_valor d-flex gap-md-3 col-12">
              <div className="col-xs-12 col-md-4">
                <div className="titulo_valor">
                  <label>Números escolhidos pelo atleta: </label>

                  <div className="valor">
                    {atletaJSON.numeroDaCamisola.map((ele, index) => {
                      return (
                        <p className="numero" key={index}>
                          {" " + ele + " "}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-md-5 d-flex align-items-end">
                <div className="titulo_valor ">
                  <label
                    for="inputNumerosDaCamisola"
                    style={{ paddingTop: "8px", marginRight: "10px" }}
                  >
                    Número para a camisola:
                  </label>

                  <input
                    onChange={(e) => {setNumeroDaCamisolaFinal(e.target.value);
                    console.log(numeroDaCamisolaFinal);}}
                    value={numeroDaCamisolaFinal}
                    type="number"
                    class="form-control numeroDoJogador"
                    id="inputCamisola1"
                    placeholder="Num"
                    required
                  />
                </div>
                <div className="d-flex">
                  <NumerosDialog
                    title="Números indisponíves"
                    dataDeNascimento={atletaJSON.dataDeNascimento}
                  ></NumerosDialog>
                </div>
              </div>
            </div>

            <Row
              className="mt-2"
              style={{
                paddingTop: "40px",
              }}
            >
           
              <Col md={4} xs={5}>
                {atletaJSON.exameMedico === null ? (
                  <p>Sem exame médico para mostrar</p>
                ) : (
                  <Button onClick={() => {window.open(atletaJSON.exameMedico, "_blank") }}>Ver exame médico</Button>
                )}
              </Col>
              <Col style={{ textAlign: "center", color: "white", backgroundColor: mainColor, borderRadius: "5px", maxWidth: "200px", maxHeight:"40px", padding: "6px" }} >
              <label style={{ color: "white"}} htmlFor={'upload-button'}>
                  
                      Adicionar exame médico
                 
              </label>
              <input id="upload-button" style={{display: 'none'}} type="file" accept="application/pdf" onChange={handleChange}/>
             
              </Col>
            </Row>

            <h3 style={{ color: mainColor, paddingTop: "50px" }}>
              Encarregado
            </h3>

            <Row>
              <div className="p-0 container_titulos_valor d-flex gap-md-3 col-12 ">
                <div className="col-xs-12 col-md-3">
                  <div className="titulo_valor">
                    <label for="inputNameAtleta">Nome Completo</label>
                    <input
                      type="text"
                      class="form-control"
                      value={nomeCompletoEncarregado}
                      onChange={(e) =>
                        setNomeCompletoEncarregado(e.target.value)
                      }
                      id="inputNameAtleta"
                      placeholder="Nome"
                      required
                    />
                  </div>
                </div>

                <div className="col-xs-12 col-md-3">
                  <div className="titulo_valor">
                    <label for="inputBirthdayAtleta">Data de Nascimento</label>
                    <input
                      class="form-control"
                      type="date"
                      onChange={(e) =>
                        setDataDeNascimentoEncarregado(e.target.value)
                      }
                      value={dataDeNascimentoEncarregado}
                      id="inputBirthdayAtleta"
                      name="birthday"
                      required
                    />
                  </div>
                </div>

                <div className="col-xs-12 col-md-3">
                  <div className="titulo_valor">
                    <label for="inputCartaoDeCidadao">Número do CC</label>
                    <input
                      type="number"
                      onChange={(e) => setNumeroCCEncarregado(e.target.value)}
                      value={numeroCCEncarregado}
                      class="form-control"
                      id="inputCartaoDeCidadao"
                      placeholder="Número do Cartão de Cidadão"
                      required
                    />
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <div className="p-0 container_titulos_valor d-flex gap-md-3 col-12 ">
                <div className="col-xs-12 col-md-6">
                  <div className="titulo_valor">
                    <label for="inputNameAtleta">Morada</label>
                    <input
                      type="text"
                      class="form-control"
                      value={moradaEncarregado}
                      onChange={(e) => setMoradaEncarregado(e.target.value)}
                      id="inputNameAtleta"
                      placeholder="Nome"
                      required
                    />
                  </div>
                </div>

                <div className="col-xs-12 col-md-3">
                  <div className="titulo_valor">
                    <label for="inputBirthdayAtleta">Contacto telefónico</label>
                    <input
                      class="form-control"
                      type="number"
                      onChange={(e) =>
                        setContactoTeleEncarregado(e.target.value)
                      }
                      value={contactoTeleEncarregado}
                      id="inputBirthdayAtleta"
                      name="birthday"
                      required
                    />
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <div className="p-0 container_titulos_valor d-flex gap-md-3 col-12 ">
                <div className="col-xs-12 col-md-6">
                  <div className="titulo_valor">
                    <label for="inputEmailEncarregado">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      value={emailEncarregado}
                      onChange={(e) => setemailEncarregado(e.target.value)}
                      id="inputEmailEncarregado"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-3">
                  <div className="titulo_valor">
                    <label for="inputCartaoDeCidadao">NIF</label>
                    <input
                      type="number"
                      onChange={(e) => setnifEncarregado(e.target.value)}
                      value={nifEncarregado}
                      class="form-control"
                      id="inputCartaoDeCidadao"
                      placeholder="Número do Cartão de Cidadão"
                      required
                    />
                  </div>
                </div>
              </div>
            </Row>
           {/*  <Row className="mt-2" style={{ paddingTop: "40px" }}>
              {atletaJSON.isEmailConfirmed ? (
                <p style={{ color: "#1B55EC", margin: 0, fontWeight: 500 }}>
                  Email confirmado
                </p>
              ) : (
                <p style={{ color: "#CC0000", margin: 0, fontWeight: 500 }}>
                  Email não confirmado
                  <span style={{ paddingLeft: "20px" }}>
                    <Button>Pedir confirmação de email</Button>
                  </span>
                </p>
              )}
            </Row> */}
            <div className="col-xs-12 col-md-5 mt-5">
              <Button
                hidden={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  console.log("A Gravar Alterações");
                  await gravarAlteracs();
                  setIsLoading(false);
                }}
              >
                Gravar Alterações
              </Button>

              <Button hidden={!isLoading} disabled={true}>
                <span
                  class="btn-primary p-3 m-5 spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading
              </Button>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default AtletasEditarDados;
