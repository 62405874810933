import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import db from "../../firebase.config";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import TituloValor from "../../components/atletas/titulo_valor_component";
import { selectAtletaId } from "../../reducers/atleta_id_reducer";
import Banner from "../../components/bannerPaginas/Banner";
import CustomizedDialogsAtleta from "../../components/dialog/dialo_pagamentos_atleta";
import RemoverAtletaDialog from "../../components/dialog/atletas/remover_atleta_dialog";

import {
  atletasCollection,
  mainColor,
  mesesCompleto,
} from "../../consts/constants";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ReferenciaDialog from "../../components/dialog/referencia_dialog";
import { setAtleta } from "../../reducers/atleta_perfil_reducer";

function AltelaPerfil() {
  const [atleta, setAtletaData] = useState({});
  const [listaDePagamentos, setListaDePagamentos] = useState([]);
  const [listaDePagamentosEfetuados, setListaDePagamentosEfetuados] = useState(
    []
  );
  const atletaID = useSelector(selectAtletaId);
  var dispatch = useDispatch();

  async function fetchAtletaV2() {
    try {
      const docRef = doc(db, atletasCollection, atletaID);
      onSnapshot(docRef, (docSnap) => {
        var listaAux = [];
        var listaAuxEfetuados = [];
        setAtletaData(docSnap.data());
        dispatch(setAtleta(JSON.stringify(docSnap.data())));
        for (const key in docSnap.data().pagamentosEmAtraso) {
          var jsonData = {};
          jsonData = docSnap.data().pagamentosEmAtraso[key];
          jsonData.idAux = key;
          console.log(jsonData);
          listaAux.push(jsonData);
        }
        for (const key in docSnap.data().pagamentosEfetuados) {
          var jsonDataE = {};
          jsonDataE = docSnap.data().pagamentosEfetuados[key];
          jsonDataE.idAux = key;
          console.log(jsonDataE);
          listaAuxEfetuados.push(jsonDataE);
        }
        setListaDePagamentos(listaAux);
        setListaDePagamentosEfetuados(listaAuxEfetuados);
      });
    } catch (error) {
      console.log(error);
    }
  }

  const navigate = useNavigate();
  const handleOnClick2 = () => {
    navigate("/atletas/atleta/editar", {
      state: { atletaId: JSON.stringify(atleta) },
    });
  };

  const handleOnClick3 = () => {
    navigate("/atletas/atleta/pagamentos", {
      state: { pagamentos: atleta.pagamentosEmAtraso },
    });
  };

  useEffect(() => {
    fetchAtletaV2();
  }, []);

  return (
    <div className="app_container_main_section">
      <Banner />
      <div className="container_atleta_perfil">
        <div className="heading">
          <h3>{atleta.nomeCompleto}</h3>
        </div>
        <div className="mt-3 gap-4 d-xs-block d-sm-flex sm-flex-row">
          <div className="mt-sm-0 mt-2">
            <Button onClick={() => handleOnClick2()}> Editar Dados </Button>
          </div>
          <div className="mt-sm-0 mt-2">
            <Button>
              {" "}
              <a style={{ color: "white" }} href="#myAnchor">
                {" "}
                Pagamentos
              </a>{" "}
            </Button>
          </div>

          <div className="mt-sm-0 mt-2">
            <CustomizedDialogsAtleta
              atletaID={atletaID}
              atleta={atleta}
            ></CustomizedDialogsAtleta>
          </div>
          <div className="mt-sm-0 mt-2">
            <RemoverAtletaDialog
              atletaID={atletaID}
              /*  encarregadoID={atleta.encarregadoAssociadoId} */
            ></RemoverAtletaDialog>
          </div>
        </div>

        <hr
          className="mt-5 mb-3"
          style={{ backgroundColor: "#1B55EC", height: 2, opacity: 1 }}
        />

        <Container fluid>
          <h3 style={{ color: mainColor, paddingTop: "20px" }}>Atleta</h3>
          <Row>
            <div className="p-0 container_titulos_valor d-flex gap-md-3 col-12 ">
              <div className="col-xs-12 col-md-3">
                <TituloValor
                  titulo="Data de nascimento:"
                  valor={atleta.dataDeNascimento}
                />
              </div>

              <div className="col-xs-12 col-md-5">
                <TituloValor titulo="Morada:" valor={atleta.morada} />
              </div>

              <div className="col-xs-12 col-md-3">
                <TituloValor
                  titulo="Nome na camisola:"
                  valor={atleta.nomeNaCamisola}
                />
              </div>
            </div>

            <div className="p-0 container_titulos_valor d-flex gap-md-3">
              <div className="col-xs-12 col-md-3">
                <TituloValor titulo="Número do CC:" valor={atleta.numeroCC} />
              </div>
              <div className="col-xs-12 col-md-3">
                <TituloValor
                  titulo="Número da camisola:"
                  valor={
                    typeof atleta.numeroDaCamisolaFinal === "undefined" ||
                    atleta.numeroDaCamisolaFinal === null
                      ? "Número por atribuir"
                      : atleta.numeroDaCamisolaFinal
                  }
                />
              </div>
            </div>
            <div className="p-0 container_titulos_valor d-flex gap-md-5">
              <Col md={4} lg={3}>
                <p style={{ fontWeight: "bold" }}>Cartão da frente:</p>
                <img
                  width={200}
                  src={atleta.pathToFrenteCC}
                  alt="CC frente"
                ></img>
              </Col>
              <Col md={4} lg={3}>
                <p style={{ fontWeight: "bold" }}>Cartão de trás:</p>
                <img
                  width={200}
                  src={atleta.pathToTrasCC}
                  alt="CC frente"
                ></img>
              </Col>
              <br></br>
            </div>

            <br></br>
          </Row>
          <h3 style={{ color: mainColor, paddingTop: "40px" }}>Encarregado</h3>

          <div className="p-0 container_titulos_valor d-flex gap-md-3 col-12 ">
            <div className="col-xs-12 col-md-3">
              <TituloValor
                titulo="Nome"
                valor={atleta.nomeCompletoEncarregado}
              />
            </div>
            <div className="col-xs-12 col-md-3">
              <TituloValor
                titulo="Data de nascimento:"
                valor={atleta.dataDeNascimentoEncarregado}
              />
            </div>

            <div className="col-xs-12 col-md-5">
              <TituloValor titulo="Morada:" valor={atleta.moradaEncarregado} />
            </div>
          </div>
          <div className="p-0 container_titulos_valor d-flex gap-md-3 col-12 ">
            <div className="col-xs-12 col-md-3">
              <TituloValor titulo="Email" valor={atleta.emailEncarregado} />
            </div>
            <div className="col-xs-12 col-md-3">
              <TituloValor
                titulo="Número CC:"
                valor={atleta.numeroCCEncarregado}
              />
            </div>

            <div className="col-xs-12 col-md-5">
              <TituloValor titulo="NIF:" valor={atleta.nifEncarregado} />
            </div>
          </div>
          <div className="p-0 container_titulos_valor d-flex gap-md-5">
            <Col md={4} lg={3}>
              <p style={{ fontWeight: "bold" }}>Cartão da frente:</p>
              <img
                width={200}
                src={atleta.frenteCCEncarregadoLink}
                alt="CC frente"
              ></img>
            </Col>
            <Col md={4} lg={3}>
              <p style={{ fontWeight: "bold" }}>Cartão de trás:</p>
              <img
                width={200}
                src={atleta.trasCCEncarregadoLink}
                alt="CC frente"
              ></img>
            </Col>
            <br></br>
          </div>
        </Container>
        <div className=" container_tabela table-responsive">
          <h2
            id="myAnchor"
            style={{
              paddingTop: "60px",
              paddingBottom: "20px",
              color: mainColor,
            }}
          >
            Pagamentos em atraso
          </h2>
          <Table className="table table-hover ">
            <TableHead>
              <TableRow>
                <th scope="col"> Tipo de pagamento </th>
                <th scope="col"> Valor </th>
                <th scope="col"> Expira em</th>
                <th scope="col">Opções:</th>
              </TableRow>
            </TableHead>

            <TableBody>
              {listaDePagamentos.length === 0 || listaDePagamentos === [] ? (
                <h3 style={{ padding: "40px" }}>Sem pagamentos em atraso</h3>
              ) : (
                listaDePagamentos.map((elem, index) => {
                  return (
                    <TableRow
                      key={index.toString() + "Row"}
                      className={index % 2 === 0 ? "blue" : "white"}
                    >
                      <TableCell key={index.toString() + "nome"}>
                        {elem.classeDaCompra === "Mensalidade"
                          ? elem.tipoDaCompra +
                            " do mês de " +
                            mesesCompleto[elem.mesDaCompra]
                          : elem.tipoDaCompra}
                      </TableCell>
                      <TableCell>
                        <p key={index.toString() + "dn"}>
                          {elem.valorDaCompra + "€"}
                        </p>
                      </TableCell>
                      <TableCell key={index.toString() + "atraso"}>
                        {elem.expiryDate}
                      </TableCell>
                      <TableCell key={index.toString() + "butn"}>
                        <ReferenciaDialog
                        atletaID={atletaID}
                        encarregadoEmail={elem.encarregadoEmail}
                         tipoDaCompra={elem.tipoDaCompra}
                         docNum={elem.cep}
                          estado={elem.estado}
                          valorRef={elem.valorDaCompra}
                          compraID={elem.idAux}
                          referencia={" " + elem.reference}
                          entidade={" " + elem.entidade}
                          valor={" " + elem.valorDaCompra + "€"}
                          expira={" " + elem.expiryDate}
                        ></ReferenciaDialog>
                        {/* 
                        <ReferenciaDialog
                              encarregadoEmail={pagamento.encarregadoEmail}
                              tipoDaCompra={pagamento.tipoDaCompra}
                              docNum={pagamento.docNum}
                              estado={
                                dateTime
                                  .toLocaleDateString("pt-PT")
                                  .replaceAll("/", "-") > pagamento.expiryAt
                                  ? "Expirou"
                                  : "Pendente"
                              }
                              valorRef={pagamento.valorDaCompra}
                              compraID={pagamento.idAux}
                              referencia={" " + pagamento.reference}
                              entidade={" " + pagamento.entidade}
                              valor={" " + pagamento.valorDaCompra}
                              expira={" " + pagamento.expiryAt}
                            ></ReferenciaDialog> */}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
          <h2
            style={{
              paddingTop: "60px",
              paddingBottom: "20px",
              color: mainColor,
            }}
          >
            Pagamentos efetuados
          </h2>

          <Table>
            <TableHead>
              <TableRow>
                <th scope="col"> Tipo de pagamento </th>
                <th scope="col"> Valor </th>
                <th scope="col"> Pago em</th>
                <th scope="col">Opções:</th>
              </TableRow>
            </TableHead>
            <TableBody>
              {listaDePagamentosEfetuados.length === 0 ||
              listaDePagamentosEfetuados === [] ? (
                <h3 style={{ padding: "40px" }}>Sem pagamentos efetuados</h3>
              ) : (
                listaDePagamentosEfetuados.map((elem, index) => {
                  return (
                    <TableRow
                      key={index.toString() + "Row"}
                      className={index % 2 === 0 ? "blue" : "white"}
                    >
                      <TableCell key={index.toString() + "nome"}>
                        {elem.classeDaCompra === "Mensalidade"
                          ? elem.tipoDaCompra +
                            " do mês de " +
                            mesesCompleto[elem.mesDaCompra]
                          : elem.tipoDaCompra}
                      </TableCell>
                      <TableCell>
                        <p key={index.toString() + "dn"}>
                          {elem.valorDaCompra + "€"}
                        </p>
                      </TableCell>
                      <TableCell key={index.toString() + "atraso"}>
                        {typeof elem.paidAt === "undefined" ||
                        elem.paidAt === null
                          ? ""
                          : elem.paidAt
                              .toDate()
                              .toLocaleDateString("pt-PT")
                              .replaceAll("/", "-")}
                      </TableCell>
                      <TableCell key={index.toString() + "butn"}>
                        <Button>Info</Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default AltelaPerfil;
