import axios from "axios";
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  updateDoc,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { atletasCollection, clubesCollection, comprasCollection } from "../consts/constants";
import db from "../firebase.config";

//{"Amount":"25.00","Entity":"11990","ExpiryDate":"","Message":"Success","OrderId":"order1234","Reference":"000783490","RequestId":"fqJLxScgpuHbaXN3eDkF","Status":"0"}
export async function criaReferenciaMultibancoViaAPI(valor) {
  try {
    console.log("criaReferenciaMultibancoViaAPI");
    console.log(valor);
    var response = await axios.post(
      "https://ifthenpay.com/api/multibanco/reference/sandbox",
      JSON.stringify({
        mbKey: "ACE-448540",
        orderId: "order1234",
        amount: valor.toString(),
        expiryDays: 8,
      })
    );

    console.log(response.data);
    return response.data;
  } catch (e) {
    console.log(e);
    console.log("eero");
    return null;
  }
}

export async function criaReferenciaEAtualizaDadaosDaCompra(atletaID,
  valor,
  docID,
  epoca,
  clubeID,
  docNum

) {
  try {
    var novaReferencia = await criaReferenciaMultibancoViaAPI(valor).then(
      async (mbData) => {
        const docRef = doc(db, comprasCollection, docID);
        var nomeDoc = "comprasEfetuadasEPendentes" + docNum;
        console.log(nomeDoc);
        const clubeDocRef = doc(db, clubesCollection, clubeID, epoca, nomeDoc);
        await setDoc(
          clubeDocRef,
          {
            Pendentes: {
              [docID]: {
                reference: mbData.Reference,
                entidade: mbData.Entity,
                expiryAt: mbData.ExpiryDate,
              },
            },
          },
          { merge: true }
        );
        await setDoc(doc(db,atletasCollection,atletaID),{
          pagamentosEmAtraso: {[docID] : {
            expiryDate: mbData.ExpiryDate,
            reference: mbData.Reference,
            entidade: mbData.Entity,
            valorDaCompra: parseFloat(valor)
          }} 
        },{merge: true} )
        return await setDoc(
          docRef,
          {
            valorDaCompra: parseFloat(valor),
            reference: mbData.Reference,
            entidade: mbData.Entity,
            expiryDate: mbData.ExpiryDate,
          },
          { merge: true }
        );
      }
    );
    return novaReferencia;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function ifThenPayMultibancoCallback(a, b, c, req, res) {
  const antiPhising = req.query.key;
  const orderId = req.query.orderId;
  const antiPhisingAux = "0o38fhj47i28cnla034jah7scf9124n78mkl03azx1p2q7em9g";
  const requestId = req.query.requestId;
  const amount = req.query.amount;
  const paymentdatetime = req.query.payment_datetime;
  const entity = req.query.entity;
  const reference = req.query.reference;
  if (antiPhisingAux === antiPhising) {
    try {
      console.log(amount);
      const q = query(
        collection(db, comprasCollection),
        where("requestId", "==", a),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      const docRef = doc(db, comprasCollection, querySnapshot.docs.at(0).id);
      await setDoc(
        docRef,
        {
          valorPago: amount,
          estado: "Confirmada",
          payedAt: paymentdatetime,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
      res.sendStatus(500);
    }
  } else {
    console.log("Chaves antiphishing diferentes");
    res.sendStatus(200);
  }
}

/* exports.ifThePayMultibancoCallback =
functions.region("europe-west3").https
    .onRequest(async (req, res)=>{
      const antiPhising = req.query.key;
      const orderId = req.query.orderId;
      const antiPhisingAux =
      "0o38fhj47i28cnla034jah7scf9124n78mkl03azx1p2q7em9g";
      const requestId = req.query.requestId;
      const amount = req.query.amount;
      const paymentdatetime = req.query.payment_datetime;
      const entity = req.query.entity;
      const reference = req.query.reference;
      if (antiPhisingAux == antiPhising) {
        try {
          await admin.firestore()
              .collection("pagamentosMultibanco")
              .where("requestId", "==", requestId )
              .get().then(async (mbPayment) => {
                await admin.firestore().collection("pagamentosMultibanco")
                    .doc(mbPayment.docs.at(0).id).set({
                      "orderId": orderId,
                      "requestId": requestId,
                      "amount": amount,
                      "payment_datetime": paymentdatetime,
                      "entity": entity,
                      "reference": reference,
                    }, {merge: true});
                await admin.firestore().collection("users").
                    doc(mbPayment.docs.at(0).data().
                        userId).get().then(async (userData) => {
                      await admin.firestore().collection("users").
                          doc(mbPayment.docs.at(0).data().userId)
                          .collection("historicoDeCarregamentos").doc().set({
                            "tipo": "Multibanco",
                            "valor": amount,
                            "saldoAntesDaCompra":
                            userData.data().bancoDeHoras,
                            "saldoDepoisDaCompra": parseFloat(amount) +
                            userData.data().bancoDeHoras,
                            "dataDePagamento": paymentdatetime,
                          });
                      await admin.firestore().collection("users")
                          .doc(mbPayment.docs.at(0).data().userId).update({
                            "bancoDeHoras": parseFloat(amount) +
                            userData.data().bancoDeHoras,
                          });
                    });
              });
          console.log("Pagamento Multibanco bem sucedido");
          res.sendStatus(200);
        } catch (error) {
          console.log(error);
          res.sendStatus(500);
        }
      } else {
        console.log("Chaves antiphishing diferentes");
        res.sendStatus(200);
      }
    }); */
