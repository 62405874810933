import React, {useState} from 'react'
import logo from "../../assets/sidebar/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { selectEpoca, setEpoca } from "../../reducers/epoca_reducer";
import { selectClube } from '../../reducers/clube_reducer';
import { NavLink } from 'react-router-dom';
import BackButton from '../back_button';


function Banner() {

    var epoca = useSelector(selectEpoca);
    var epocaTItle = epoca.substring(0, 4) + "/" + epoca.substring(7, 9);
    const [responsiveWidth, setResponsiveWidth] = useState(window.innerWidth);

    var clube = useSelector(selectClube);
    

  return (
    <div className='BannerPaginas'>
        

        {
            responsiveWidth > 1024 ? 
            (
                <div className='banner_container'>
                    <div className="epoca">
                        <p><strong>Época</strong> {epocaTItle}</p>
                    </div>
                    <div className="clube">
                        <img src={logo} />
                    </div>
                </div>
            )
            :
            (
                <div className='banner_container'>
                    <div className="epoca">
                        <p>
                        <NavLink className={(active) => (active.isActive ? 'active' : '')}  to="/dashboard"  >
                                <strong>{clube}</strong>
                        </NavLink>
                        </p>
                    </div>
                    <div className='mobileburguer'>
                        <div className='burguer'
                            onClick={
                                () => {
                                    document.querySelector('aside').classList.toggle('openAside')
                                }
                            }>
                                <span class="material-symbols-outlined" style={{fontWeight:700,display:"block"}} >menu</span>
                                
                        </div>
                        
                    </div>
                </div>
                
            )
        }
            
            
        
    </div>
  )
}

export default Banner


