import { createSlice } from "@reduxjs/toolkit";

export const encarregadoIdSlice = createSlice({
  name: "encarregadoId",
  initialState: {
    value: "",
  },
  reducers: {
    setEncarregadoId: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEncarregadoId } = encarregadoIdSlice.actions;

export const selectEncarregadoId = (state) => state.encarregadoId.value;

export default encarregadoIdSlice.reducer;
