import { createSlice } from "@reduxjs/toolkit";

export const comprasSlice = createSlice({
  name: "compras",
  initialState: {
    value: [],
  },
  reducers: {
    setCompras: (state, action) => {
      action.payload.forEach((element) => state.value.push(element));
    },
    resetCompras: (state) => {
      state.value = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCompras, resetCompras } = comprasSlice.actions;

export const selectCompras = (state) => state.compras.value;

export default comprasSlice.reducer;
