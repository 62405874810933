import { query, collection, where, limit, getDocs } from "firebase/firestore";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import db, { auth } from "../firebase.config";
import { selectClube, setClube } from "../reducers/clube_reducer";
import { selectEpoca, setEpoca } from "../reducers/epoca_reducer";
import "./spinner.css";

export default function LoadingSpinner() {
  var clube = useSelector(selectClube);
  var epoca = useSelector(selectEpoca);
  var dispatch = useDispatch();

  async function fetchReducers() {
    console.log("fetchin reducers");
    const q = query(
      collection(db, "clubes"),
      where("email", "==", auth.currentUser.email),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      clube = doc.data().clube;
      epoca = doc.data().epoca;
      dispatch(setClube(clube));
      dispatch(setEpoca(epoca));
      console.log(doc.id, " => ", doc.data());
    });
  }

  useEffect(() => {
    fetchReducers();
  }, []);
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
}
