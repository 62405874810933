import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectclubeId } from "../../../reducers/clube_id_reducer";
import { selectEpoca } from "../../../reducers/epoca_reducer";
import {
  deleteAtletasAccount,
  deleteComprasPendentes,
} from "../../../services/atletaServices/atleta_editar_dados_services";

function RemoverAtletaDialog(props) {
  const [isHiden, setIsHiden] = useState(true);
  const [smShow, setSmShow] = useState(false);
  var navigate = useNavigate();
  var clubeId = useSelector(selectclubeId);
  var epoca = useSelector(selectEpoca);

  return (
    <>
      <Button onClick={() => setSmShow(true)} className="me-2">
        Remover Atleta
      </Button>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Titulo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Ao remover este atleta tambêm será removido</p>
          <ul>
            <li>O encarregado associado</li>
            <li>A inscrição associada</li>
            <li>Pagamentos pendentes associados</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            hidden={!isHiden}
            onClick={async () => {
              setIsHiden(false);
              console.log("A Remover");
              await deleteAtletasAccount(props.atletaID, clubeId, epoca).then(
                (value) => {
                  console.log(value);
                }
              );
              await deleteComprasPendentes(props.atletaID, clubeId, epoca).then(
                (value) => {
                  console.log(value);
                }
              );

              navigate(-1);
              setIsHiden(true);
              setSmShow(false);
            }}
          >
            Remover
          </Button>
          <Button disabled hidden={isHiden}>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RemoverAtletaDialog;
