import { createSlice } from "@reduxjs/toolkit";

export const pagamentosEmFaltaSlice = createSlice({
  name: "pagamentosEmFalta",
  initialState: {
    value: "",
  },
  reducers: {
    setpagamentosEmFalta: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setpagamentosEmFalta } = pagamentosEmFaltaSlice.actions;

export const selectpagamentosEmFalta = (state) => state.pagamentosEmFalta.value;

export default pagamentosEmFaltaSlice.reducer;
