import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";

import { where, query, collection, getDocs, limit } from "firebase/firestore";
import db from "../../firebase.config";
import { useDispatch } from "react-redux";
import { setClube } from "../../reducers/clube_reducer";
import { setEpoca } from "../../reducers/epoca_reducer";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Button, Form, Modal } from "react-bootstrap";
import logotipoMacrofoot from "../../assets/macrofoot.svg";
import { setemail } from "../../reducers/email_reducer";


function LoginScreen() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const logInWithEmailAndPassword = async (email, password) => {
    try {
      dispatch(setemail(email))
      var user = await signInWithEmailAndPassword(auth, email, password);
      var clube;
      var epoca;
      const q = query(
        collection(db, "clubes"),
        where("email", "==", email),
        limit(1)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        clube = await doc.data().clube;
        epoca = await doc.data().epoca;
        dispatch(setClube(clube));
        dispatch(setEpoca(epoca));
        console.log(doc.id, " => ", doc.data());
      });
      console.log(user);
      return [clube, epoca];
    } catch (err) {
      console.log("err");
      console.error(err);
    }
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) {
      return;
    }
  }, [user, loading]);

  return (
    <div className="Auth-form-container">
      <div className="Auth-form">
        <div className="Auth-form-content" style={{textAlign: "center"}}>
          <img src={logotipoMacrofoot} alt="logotipo" className="text-center" />
          <h3 className="Auth-form-title"> Log in</h3>
          <div className="form-group mt-3" style={{textAlign: "left"}}>
            <label>Email </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="form-control mt-1"
              placeholder="Introduzir email"
            />
          </div>
          <div className="form-group mt-3" style={{textAlign: "left"}}>
            <label>Password</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="form-control mt-1"
              placeholder="Introduzir password"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                logInWithEmailAndPassword(email, password);
              }}
            >
              Entrar
            </button>
          </div>
          <p className="forgot-password text-right mt-2">
            Esqueceu-se da sua password?
          </p>
          <button className="btn btn-primary " onClick={handleShow}>
            Recuperar Password
          </button>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Recuperar Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <p>
                  Será enviada uma mensagem para o seu email com instrunções de
                  como alterar a password da conta.
                </p>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" autoFocus />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Enviar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;

/* const auth = getAuth();
  signInWithEmailAndPassword(
    auth,
    "estefutebolclube@gmail.com",
    "dGu04RWCW5aWOOxuJ7Wy"
  )
    .then((value) => {
      console.log(value);
    })
    .then(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
        } else {
        }
      });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    }); */
