export default class Compra {
  constructor(
    cep,
    clube,
    status,
    requestId,
    encarregadoEmail,
    encarregadoNome,
    encarregadoDataDeNascimento,
    atletaNome,
    atletaId,
    atletaDataDeNascimento,
    createdAt,
    tipoDaCompra,
    estado,
    valorDaCompra,
    anoCreatedAt,
    mesCreatedAt,
    diaCreatedAt,
    epoca,
    entidade,
    expiryDate,
    orderID,
    reference,
    classeDaCompra,
    mesDaCompra
  ) {
    this.atletaNome = atletaNome;
    this.cep = cep;
    this.atletaId = atletaId;
    this.atletaDataDeNascimento = atletaDataDeNascimento;
    this.clube = clube;
    this.encarregadoEmail = encarregadoEmail;
    this.encarregadoNome = encarregadoNome;
    this.encarregadoDataDeNascimento = encarregadoDataDeNascimento;
    this.createdAt = createdAt;
    this.tipoDaCompra = tipoDaCompra;
    this.estado = estado;
    this.valorDaCompra = valorDaCompra;
    this.anoCreatedAt = anoCreatedAt;
    this.mesCreatedAt = mesCreatedAt;
    this.diaCreatedAt = diaCreatedAt;
    this.epoca = epoca;
    this.entidade = entidade;
    this.expiryDate = expiryDate;
    this.orderID = orderID;
    this.reference = reference;
    this.requestId = requestId;
    this.status = status;
    this.classeDaCompra = classeDaCompra;
    this.mesDaCompra = mesDaCompra;
  }

  toJson() {
    return {
      cep: this.cep,
      atletaDataDeNascimento: this.atletaDataDeNascimento,
      atletaId: this.atletaId,
      atletaNome: this.atletaNome,
      clube: this.clube,
      encarregadoEmail: this.encarregadoEmail,
      encarregadoNome: this.encarregadoNome,
      encarregadoDataDeNascimento: this.encarregadoDataDeNascimento,
      createdAt: this.createdAt,
      tipoDaCompra: this.tipoDaCompra,
      estado: this.estado,
      valorDaCompra: this.valorDaCompra,
      anoCreatedAt: this.anoCreatedAt,
      mesCreatedAt: this.mesCreatedAt,
      diaCreatedAt: this.diaCreatedAt,
      epoca: this.epoca,
      entidade: this.entidade,
      expiryDate: this.expiryDate,
      orderID: this.orderID,
      reference: this.reference,
      requestId: this.requestId,
      status: this.status,
      mesDaCompra: this.mesDaCompra,
      classeDaCompra: this.classeDaCompra,
    };
  }
}
