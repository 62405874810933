import { createSlice } from "@reduxjs/toolkit";

export const atletasSlice = createSlice({
  name: "atletas",
  initialState: {
    value: [],
  },
  reducers: {
    setAtletas: (state, action) => {
      action.payload.forEach((element) =>
        state.value.push(JSON.stringify(element))
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAtletas } = atletasSlice.actions;

export const selectAtletas = (state) => state.atletas.value;

export default atletasSlice.reducer;
