import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import db from "../../firebase.config";
import { selectAtletaPerfil } from "../../reducers/atleta_perfil_reducer";
import { selectClube } from "../../reducers/clube_reducer";
import { selecEncarregadoPerfil } from "../../reducers/encarregado_reducer";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Banner from "../../components/bannerPaginas/Banner";

function AtletasConfirmados() {
  const isEven = (num) => num % 2 === 0;
  var clube = useSelector(selectClube);
  const [pagamentos, setPagamentos] = useState([]);

  const encarregadoString = useSelector(selecEncarregadoPerfil);
  const encarregado = JSON.parse(encarregadoString);
  const atletaID = encarregado.idsAtltasAssociados[0];

  console.log(encarregadoString);

  async function fetchPagamentos() {
    console.log("fetching From Firebase");

    const mensalidadeQuery = query(
      collection(db, "compras"),
      where("clube", "==", clube),
      where("atletaId", "==", atletaID),
      where("estado", "==", "Confirmada")
    );

    onSnapshot(mensalidadeQuery, (querySnapshot) => {
      var getPagamentos = [];
      querySnapshot.forEach((doc) => {
        getPagamentos.push(doc);
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
          }
          if (change.type === "modified") {
          }
          if (change.type === "removed") {
          }
        });
      });
      setPagamentos(getPagamentos);
      console.log(getPagamentos);
    });
  }

  useEffect(() => {
    fetchPagamentos();
  }, []);

  function sortByNome() {}

  function sortByDataDeNascimento() {}

  function sortByPagamentosEmAtraso() {}

  return (

    <div className="app_container_main_section">
    <Banner />
      <div className="app_container_main_section_content">

        <div className="heading">
            <h3>Pagamentos Efetuados</h3>
        </div>

        {/* Tabela */}
        <div className="tabela_generica w-100 table-responsive">
            <Table className="table  table-hover ">
              <TableHead >
                <TableRow>
                  <th scope="col" onClick={() => sortByNome()}> Nome do encarregado </th>
                  <th scope="col" onClick={() => sortByDataDeNascimento()}> Forma de Pagamento </th>
                  <th scope="col" onClick={() => sortByPagamentosEmAtraso()}> Tipo de Pagamento </th>
                  <th scope="col" onClick={() => sortByPagamentosEmAtraso()}> Valor da Compra</th>
                  <th>Opcoes:</th>
                </TableRow>
              </TableHead>

              <TableBody>
              {pagamentos.map((pagamentoI, index) => {
              const pagamento = pagamentoI.data();
              return (          
                    <TableRow key={index.toString() + "Row"}   className={index % 2 === 0 ? "blue" : "white"} >

                      <TableCell >
                      <p>{pagamento.atletaNome}</p>
                      </TableCell>

                      <TableCell>
                      <p>{pagamento.tipo}</p>
                      </TableCell>

                      <TableCell>
                      <p>{pagamento.tipoDaCompra}</p>
                      </TableCell>

                      <TableCell>
                      <p>{pagamento.valorDaCompra}</p>
                      </TableCell>
                      
          
                    </TableRow>
                      
                    );
                  })}
              </TableBody>
            </Table>
        </div>
      </div>
    </div>

  );
}

export default AtletasConfirmados;
