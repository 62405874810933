import React from "react";
import {
  getDocs,
  where,
  query,
  collection,
  Timestamp,
  doc,
  updateDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import db from "../firebase.config";
import Compra from "../models/compra";
import { selectClube } from "../reducers/clube_reducer";
import { criaReferenciaMultibancoViaAPI } from "./multivanco_services";
import {
  adicionaCompra,
  adicionaCompraAoClube,
  adicionaCompraAoClube2,
  atualizarNumeroDoCEPNaCompra,
  atulizaValoresAPagarAoClube,
} from "./compras_services";
import { addPagamentosEmAtraso } from "./encarregados_services";
import { criaEmailBOdy, enviarEmailPagamentos } from "./mail_services";
import { legacy_createStore } from "@reduxjs/toolkit";
import {
  atletasCollection,
  clubesCollection,
  inscricoesCollection,
  mesesCompleto,
} from "../consts/constants";

export async function getPagamentos(clube) {
  try {
    const q = query(collection(db, "pagamentos"), where("clube", "==", clube));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs;
    /* querySnapshot.forEach((doc) => {
            
            console.log(doc.id, " => ", doc.data());
          }); */
  } catch (error) {
    console.log("error");
    console.log(error);
  }
}

export function veSeAsMensalidadesTempeloMenosUmMes(mapMesesEscolhidos) {
  var controlador = true;

  console.log("mapMesesEscolhidos");
  console.log(mapMesesEscolhidos);
  if (mapMesesEscolhidos === {}) {
    return controlador;
  }
  for (const key in mapMesesEscolhidos) {
    if (
      mapMesesEscolhidos[key] === [] ||
      mapMesesEscolhidos[key].length === 0
    ) {
      controlador = false;
    }
  }
  return controlador;
}

export async function enviarPagamentos(
  clube,
  epoca,
  mapNomeValor,
  inscPendValu,
  enviarEmail,
  clubeId,
  mapMesesEscolhidos,
  cepRed
) {
  try {
    var inscPend = inscPendValu.data();
    var htmlBody =
      " <style>" +
      ".text {" +
      " align-self: flex-end;" +
      "}" +
      ".image {" +
      " width: 120px;" +
      " height: 120px;" +
      "}" +
      ".paragraph {" +
      "display: flex;" +
      "}" +
      "</style>";

    console.log("mapNomeValor");
    console.log(mapNomeValor);

    /* for (let index = 0; index < mapNomeValor.size; index++) {
      
      var key = Array.from(mapNomeValor.keys())[index];
      var value = mapNomeValor.get(key); */
    for (let pair of mapNomeValor) {
      var [key, value] = pair;
      console.log("pair");

      var multibancoData = await criaReferenciaMultibancoViaAPI(value);

      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1;
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();

      var compra = new Compra();
      compra.anoCreatedAt = year;
      compra.diaCreatedAt = day;
      compra.mesCreatedAt = month;
      compra.createdAt = Timestamp.fromDate(dateObj);
      compra.clube = clube;
      compra.encarregadoEmail = inscPend.emailEncarregado;
      compra.encarregadoNome = inscPend.nomeCompletoEncarregado;
      compra.encarregadoDataDeNascimento = inscPend.dataDeNascimentoEncarregado;
      compra.atletaNome = inscPend.nomeCompletoAtleta;
      compra.atletaId = inscPend.atletaId;
      compra.atletaDataDeNascimento = inscPend.dataDeNascimentoAtleta;
      compra.tipoDaCompra = key;
      compra.estado = "Pendente";
      compra.valorDaCompra = parseFloat(value);
      compra.entidade = multibancoData.Entity;
      compra.orderID = multibancoData.OrderId;
      compra.reference = multibancoData.Reference;
      compra.requestId = multibancoData.RequestId;
      compra.status = multibancoData.Status;
      compra.expiryDate = multibancoData.ExpiryDate;
      compra.epoca = epoca;
      compra.cep = cepRed;

      var compraAdcio;
      var emailData;
      var cep;

      if (typeof mapMesesEscolhidos[key] === "undefined") {
        console.log("Anuidade bp0");
        // enviar pagamentos anuais
        compra.classeDaCompra = "Anual";
        compra.mesDaCompra = null;
        console.log("Anuidade bp1");

        compraAdcio = await adicionaCompra(compra, clubeId);
        cep = await adicionaCompraAoClube2(
          cepRed,
          compraAdcio.id,
          compra,
          clubeId,
          epoca
        );
        compra.cep = cep;
        await atualizarNumeroDoCEPNaCompra(compraAdcio.id, cep);
        await addPagamentosEmAtraso(compraAdcio.id, compra);

        if (enviarEmail) {
          emailData = criaEmailBOdy(
            compra.tipoDaCompra,
            compra.valorDaCompra.toString(),
            compra.entidade,
            compra.reference,
            compra.expiryDate
          );
          htmlBody = htmlBody + emailData;
        }
      }
      if (typeof mapMesesEscolhidos[key] !== "undefined") {
        for (const indexNrMes in mapMesesEscolhidos[key]) {
          console.log("Mensalidade bp0");
          compra.classeDaCompra = "Mensalidade";
          compra.mesDaCompra = mapMesesEscolhidos[key][indexNrMes];
          console.log("Mensalidade bp1");

          compraAdcio = await adicionaCompra(compra, clubeId);
          cep = await adicionaCompraAoClube2(
            cepRed,
            compraAdcio.id,
            compra,
            clubeId,
            epoca
          );
          compra.cep = cep;
          await atualizarNumeroDoCEPNaCompra(compraAdcio.id, cep);
          await addPagamentosEmAtraso(compraAdcio.id, compra);

          if (enviarEmail) {
            emailData = criaEmailBOdy(
              compra.tipoDaCompra +
                " do mês de " +
                mesesCompleto[mapMesesEscolhidos[key][indexNrMes]],
              compra.valorDaCompra.toString(),
              compra.entidade,
              compra.reference,
              compra.expiryDate
            );
            htmlBody = htmlBody + emailData;
          }
        }
      }
    }
    if (enviarEmail) {
      await enviarEmailPagamentos(
        compra.encarregadoEmail,
        "Pagamentos ao " + clube,
        htmlBody
      );
    }

    await updateAlunoParaInscrito(inscPend.atletaId);
    await trocaOAlunoDaListaDeNoaInscritosParaInscritos(
      clubeId,
      epoca,
      inscPend.atletaId,
      mapNomeValor
    );
    await updateEstadoDaInscricao(inscPendValu.id);
    //Ativa mensalidade no Encarregado
  } catch (error) {
    console.log("error");
    console.log(error);
  }
}

export async function updateAlunoParaInscrito(atletaID) {
  try {
    console.log("A atualizar o valor estaInscrito ...");
    var docRef = doc(db, atletasCollection, atletaID);
    await setDoc(docRef, { estaInscrito: true }, { merge: true });
    console.log("A atualizar o valor estaInscrito feito!!!");
    return true;
  } catch (error) {
    console.log("Falhou a atualizar o valor estaInscrito :(( ");
    console.log(error);
    return null;
  }
}

export async function trocaOAlunoDaListaDeNoaInscritosParaInscritos(
  clubeId,
  epoca,
  atletaID,
  mapNomeValor
) {
  try {
    var jsonComIDRemovido = {};
    console.log("A trocar Aluno de inscrito para nao inscrito");
    var docRef = doc(db, clubesCollection, clubeId, epoca, "atletas");
    var mapNomeValorSize = mapNomeValor.size;
    var pagamnetosEmatraso;
    if (mapNomeValorSize === 0) {
      pagamnetosEmatraso = false;
    } else {
      pagamnetosEmatraso = true;
    }
    var docSnap = await getDoc(docRef);
    var atletaAtrocarData = docSnap.data().Inscritos_nao[atletaID];
    console.log("atletaAtrocarData");
    console.log(atletaAtrocarData);
    var newInscritosData = docSnap.data().Inscritos;
    newInscritosData[atletaID] = atletaAtrocarData;
    newInscritosData[atletaID]["PagamentosEmAtraso"] = pagamnetosEmatraso;
    console.log(newInscritosData);
    await setDoc(docRef, { Inscritos: newInscritosData }, { merge: true });

    for (const key in docSnap.data().Inscritos_nao) {
      if (key !== atletaID) {
        jsonComIDRemovido[key] = docSnap.data().Inscritos_nao[key];
      }
    }

    console.log("jsonComIDRemovido");
    console.log(jsonComIDRemovido);

    await updateDoc(docRef, { Inscritos_nao: jsonComIDRemovido });
  } catch (error) {
    console.log(error);
  }
}

async function updateEstadoDaInscricao(docId) {
  try {
    const inscrPendRef = doc(db, inscricoesCollection, docId);
    await updateDoc(inscrPendRef, { estado: "Pagamentos Enviados" });
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*        await MultibancoServices().createMulitbancoPayment(compra: _compra).then((compraRetornada) async {
            htmlBody = htmlBody +
                EmailServices().criaEmailBOdy(
                entidade: compraRetornada.entidade,
                nome: _compra.tipoDaCompra,
                referencia: compraRetornada.reference,
                valor: _compra.valorDaCompra.toString(),
                ); });
        }
        await InscricoesServices().adicionadDadosMultibancoAInscricao(
            inscricaoID: listaDeSnaps[index].id,
            mapaDosPagamentos: mapsPAInsc);

        await EmailServices()
            .enviarPagamentos(
        assunto:
            'Pagamentos',
        emailTo:
            listaDeSnaps[index]['emailEncarregado'],
        htmlbody:
            htmlBody,
        )
            .then((value) {
        const ScaffoldMessenger(
            child: SnackBar(
            content: Text('Dados enviados para o email'),
        ));
        });
        //TODO testar se isto funciona
        await EncarregadosServices()
            .ativaPagamentosNaInscricaoPendente(docID: listaDeSnaps[index]['encarregadoId']);  */
