import { createSlice } from "@reduxjs/toolkit";

export const epocaSlice = createSlice({
  name: "epoca",
  initialState: {
    value: "",
  },
  reducers: {
    setEpoca: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEpoca } = epocaSlice.actions;

export const selectEpoca = (state) => state.epoca.value;

export default epocaSlice.reducer;
