import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { JsonToExcel } from "react-json-to-excel";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../../components/bannerPaginas/Banner";
import { clubesCollection } from "../../consts/constants";
import db from "../../firebase.config";
import { selectclubeId } from "../../reducers/clube_id_reducer";
import { selectEpoca } from "../../reducers/epoca_reducer";
import { capitalizeFirstLetter } from "../../services/functions_aux";

var atletasNaoInscritosConstVariavelGlob = [];

function AtletasNaoInscritosScreen() {
  var epoca = useSelector(selectEpoca);
  var clubeId = useSelector(selectclubeId);
  const [listaDeAtletas, setListaDeAtletas] = useState([]);
  const [listaDeIds, setlistaDeIds] = useState([]);

  var dispatch = useDispatch();

  async function fetchAtletasNaoInscrtos() {
    var docRef = doc(db, clubesCollection, clubeId, epoca, "atletas");
    const unsub = onSnapshot(docRef, (doc) => {
      var listaAuxId2 = [];
      atletasNaoInscritosConstVariavelGlob = [];
      for (const key in doc.data().Inscritos_nao) {
        listaAuxId2.push({ [key]: doc.data().Inscritos_nao[key] });
        atletasNaoInscritosConstVariavelGlob.push({
          [key]: doc.data().Inscritos_nao[key],
        });
      }
      setListaDeAtletas(listaAuxId2);
    });
  }

  const [nomeOrd, setnomeOrd] = useState([false]);
  const [dataDeNAsOrd, setdataDeNAsOrd] = useState([false]);
  const [pagsEmAtraOrd, setpagsEmAtraOrd] = useState([false]);

  function sortByNome() {
    listaDeAtletas.sort((a, b) => {
      var aux;
      var aux2;
      for (var key in a) {
        aux = a[key];
      }
      for (var key2 in b) {
        aux2 = b[key2];
      }
      if (nomeOrd) {
        return aux.nomeDoAtleta > aux2.nomeDoAtleta ? 1 : -1;
      } else {
        return aux.nomeDoAtleta < aux2.nomeDoAtleta ? 1 : -1;
      }
    });
    setnomeOrd(!nomeOrd);
  }

  function sortByDataDeNascimento() {
    listaDeAtletas.sort((a, b) => {
      var aux;
      var aux2;
      for (var key in a) {
        aux = a[key];
      }
      for (var key2 in b) {
        aux2 = b[key2];
      }
      if (dataDeNAsOrd) {
        return aux.dataDeNascimento > aux2.dataDeNascimento ? 1 : -1;
      } else {
        return aux.dataDeNascimento < aux2.dataDeNascimento ? 1 : -1;
      }
    });
    setdataDeNAsOrd(!dataDeNAsOrd);
  }

  function filtrarPorDataDeNascimento(ano) {
    if (ano === "") {
      setListaDeAtletas(atletasNaoInscritosConstVariavelGlob);
    } else {
      var listaAux = [].concat(atletasNaoInscritosConstVariavelGlob);
      var listaAUxAux = [];
      listaAux.forEach((ele) => {
        var aux;
        for (var key in ele) {
          aux = ele[key];
        }
        return aux.dataDeNascimento.substring(0, 4) === ano
          ? listaAUxAux.push(ele)
          : null;
      });

      setListaDeAtletas(listaAUxAux);
    }
  }

  function pesquisaPorNome() {
    var name = document.getElementById("pesquisarNome").value;
    if (name === "") {
      setListaDeAtletas(atletasNaoInscritosConstVariavelGlob);
    } else {
      var listaAux = [].concat(atletasNaoInscritosConstVariavelGlob);
      var listaAUxAux = [];
      listaAux.forEach((ele) => {
        var aux;
        for (var key in ele) {
          aux = ele[key];
        }
        if (aux.nomeDoAtleta.includes(name)) {
          return listaAUxAux.push(ele);
        } else if (aux.nomeDoAtleta.includes(capitalizeFirstLetter(name))) {
          return listaAUxAux.push(ele);
        }
        return null;
      });
      setListaDeAtletas(listaAUxAux);
    }
  }

  function pesquisaPorEmail() {
    var email = document.getElementById("pesquisarEmail").value;
    if (email === "") {
      setListaDeAtletas(atletasNaoInscritosConstVariavelGlob);
    } else {
      var listaAux = [].concat(atletasNaoInscritosConstVariavelGlob);
      var listaAUxAux = [];
      listaAux.forEach((ele) => {
        var aux;
        for (var key in ele) {
          aux = ele[key];
        }
        if (aux.emailDoEncarregado.includes(email)) {
          return listaAUxAux.push(ele);
        }
        return null;
      });

      setListaDeAtletas(listaAUxAux);
    }
  }

  useEffect(() => {
    fetchAtletasNaoInscrtos();
  }, []);
  const renderDocList = () => {
    return (
      <div className="container_atletas">
        <Banner />

        <div className="container_atletas_info">
          {/* Titulo */}
          <div className="heading_pagina">
            <div className="titulo_pagina">
              <h3>Atletas</h3>
              <p>
                Resultados: <span>{listaDeAtletas.length}</span>
              </p>
            </div>

            {/* <div className="exportar_excel">
              <JsonToExcel
                title="Exportar Csv"
                data={atletasExcel}
                fileName="sample-file"
                className="excel_btn"
              />
            </div> */}
          </div>

          <Container fluid>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div class="input-group gap-3">
                      <input
                        id="filtrarPorData"
                        type="text"
                        maxlength="4"
                        name="country_code"
                        title="Error Message"
                        pattern="[1-9]{1}[0-9]{9}"
                        class=" form-control"
                        placeholder="Filtrar por data de nascimento"
                      ></input>
                      <div class="input-group-append col-4">
                        <button
                          class="btn d-flex align-items-center"
                          type="button"
                          onClick={() =>
                            filtrarPorDataDeNascimento(
                              document.getElementById("filtrarPorData").value
                            )
                          }
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                          &nbsp;
                          <span className="">Filtrar</span>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col>
                    <div class="input-group gap-3">
                      <input
                        id="pesquisarNome"
                        type="text"
                        class=" form-control"
                        placeholder="Pesquisar por nome"
                      />
                      <div class="input-group-append col-4">
                        <button
                          class="btn d-flex align-items-center "
                          type="button"
                          onClick={() => pesquisaPorNome()}
                        >
                          <span class="material-symbols-outlined">search</span>
                          &nbsp;
                          {/* "d-none d-sm-block */}
                          <span className="">Pesquisar</span>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col>
                    <div class="input-group gap-3">
                      <input
                        id="pesquisarEmail"
                        type="text"
                        class=" form-control"
                        placeholder="Pesquisar por email do encarregado"
                      ></input>
                      <div class="input-group-append col-4">
                        <button
                          class="btn d-flex align-items-center "
                          type="button"
                          onClick={() => pesquisaPorEmail()}
                        >
                          <span class="material-symbols-outlined">search</span>
                          &nbsp;
                          <span className="">Pesquisar</span>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              {/* Tabela */}
              <div className=" container_tabela table-responsive">
                <Table className="table table-hover ">
                  <TableHead>
                    <TableRow>
                      <th
                        scope="col"
                        onClick={() => {
                          sortByNome();
                        }}
                      >
                        {" "}
                        Nome{" "}
                      </th>
                      <th scope="col" onClick={() => sortByDataDeNascimento()}>
                        {" "}
                        Ano de Nascimento
                      </th>
                      <th scope="col"> Email do encarregado</th>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {listaDeAtletas === [] || listaDeAtletas.length === 0 ? (
                      <h4 style={{ paddingTop: "20px" }}>
                        Não existem atletas não inscritos
                      </h4>
                    ) : (
                      listaDeAtletas.map((atleta, index) => {
                        var jsonData;
                        for (const key in atleta) {
                          jsonData = atleta[key];
                        }
                        return (
                          <TableRow
                            key={index.toString() + "Row"}
                            className={index % 2 === 0 ? "blue" : "white"}
                          >
                            <TableCell key={index.toString() + "nome"}>
                              {jsonData.nomeDoAtleta}
                            </TableCell>
                            <TableCell>
                              {jsonData.dataDeNascimento.substring(0, 4)}
                            </TableCell>
                            <TableCell>{jsonData.emailDoEncarregado}</TableCell>
                            {/*  <TableCell>
                              <button
                                className="btn btn-primary buttonCenter"
                                type="button"
                                onClick={() => {
                                  console.log(listaDeAtletas[atleta]);
                                }}
                              >
                                Informações
                              </button>
                            </TableCell> */}
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  };

  return <>{renderDocList()}</>;
}

export default AtletasNaoInscritosScreen;
